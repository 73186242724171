import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import {
  updateBinCheckValue,
  updateBinCodeCountryValue,
  updateBinConfigValue,
} from "../../redux/users/action";
import FraudCheckbox from "../FraudCheckbox";

const BinConfigStyles = styled.div`
  padding: 10px 16px 16px 16px;
  & label {
    margin-bottom: 12px;
  }

  & > div {
    margin-bottom: 12px;
  }
`;

const BinCheckConfig = ({ userProfile }) => {
  const dispatch = useDispatch();

  const updateBinCheckbox = (e) => {
    const value = e.target.checked;
    dispatch(updateBinCheckValue(value));
  };

  const updateBinConfigCheckbox = (e) => {
    const name = e.target.name;
    const value = e.target.checked;
    dispatch(updateBinConfigValue(value, name));
  };

  const updateBinCodeCountryCheckbox = (e) => {
    const value = e.target.checked;
    dispatch(updateBinCodeCountryValue(value));
  };

  return (
    <div>
      <FraudCheckbox
        checked={userProfile.bin_check.status || false}
        title="bin_check"
        name="bin_check"
        value={userProfile.bin_check.status || false}
        onChange={(e) => updateBinCheckbox(e)}
      />
      {userProfile.bin_check.status && (
        <BinConfigStyles>
          <FraudCheckbox
            checked={userProfile.bin_check.country_code?.status || false}
            title="country_code"
            name="country_code"
            value={userProfile.bin_check.country_code?.status || false}
            onChange={(e) => updateBinCodeCountryCheckbox(e)}
          />
          <div>Профили:</div>
          <FraudCheckbox
            checked={userProfile.bin_check.profiles.kz}
            title="kz"
            item={userProfile.bin_check.profiles.kz}
            name="kz"
            value={userProfile.bin_check.profiles.kz}
            onChange={(e) => updateBinConfigCheckbox(e)}
            info="Только Казахстан"
          />
          <FraudCheckbox
            checked={userProfile.bin_check.profiles.cis}
            title="cis"
            item={userProfile.bin_check.profiles.cis}
            name="cis"
            value={userProfile.bin_check.profiles.cis}
            onChange={(e) => updateBinConfigCheckbox(e)}
            info="СНГ (Азербайджан, Армения, Белоруссия, Казахстан, Киргизия, Молдавия, Россия, Таджикистан, Узбекистан, Грузия, Украина)"
          />
        </BinConfigStyles>
      )}
    </div>
  );
};

export default BinCheckConfig;
