import {CSSTransition} from "react-transition-group";
import {ModalBlockStyles, ModalOverlayStyles, ModalWrapperStyles} from "../styles/Modal";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import React, {useRef} from "react";
import {CloseCrossStyles} from "../styles/CloseCross";

const Modal = ({isShowing, hide, children, title}) => {
    const ref = useRef(null);

    useOnClickOutside(ref, hide)

    return (
        <CSSTransition in={isShowing} classNames='modal' unmountOnExit timeout={300}>
            <ModalOverlayStyles>
                <ModalWrapperStyles>
                    <CSSTransition in={isShowing} timeout={300} classNames='modal-block' unmountOnExit>
                        <ModalBlockStyles ref={ref}>
                            <div className='modal-block__header'>
                                <div className='modal-block__header__title'>
                                    <h3>{title}</h3>
                                </div>
                                <div className='modal-block__header__close' onClick={hide}>
                                    <CloseCrossStyles />
                                </div>
                            </div>
                            <div className='modal-block__main'>
                                {children}
                            </div>
                        </ModalBlockStyles>
                    </CSSTransition>
                </ModalWrapperStyles>
            </ModalOverlayStyles>
        </CSSTransition>
    )
};

export default Modal;