import CryptoJS from 'crypto-js';
import {SIGN_IN_FAILURE, SIGN_IN_REQUEST, SIGN_IN_SUCCESS, SIGN_OUT} from "./constants";
import {userAuth} from "../../network/kaf-api";

export const requestSignIn = ({login, password}) => async dispatch => {
    dispatch({type: SIGN_IN_REQUEST});
    try {
        const response = await userAuth(login, password);

        if (response.data.code === 0) {
            localStorage.setItem('session', new Date().getTime().toString());
            dispatch({type: SIGN_IN_SUCCESS,
                payload: {
                    code: CryptoJS.MD5(password).toString(),
                    login: login,
                    name: response.data.body.name,
                    permissions: response.data.body?.permissions?.pages.split(',')
                }
            });
        } else {
            dispatch({type: SIGN_IN_FAILURE, payload: {message: 'Произошла непредвиденная ошибка'}});
        }
    } catch (e) {

    }
};

export const signOut = () => dispatch => {
    dispatch({type: SIGN_OUT});
};