import styled from "styled-components";

export const NavStyles = styled.nav`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  padding: 20px 0;
  background-color: white;
  
  .nav-item {
    align-items: center;
    display: flex;
    padding: 8px 24px;
    border-left: 1px solid rgba(35,31,32,0.5);
    text-decoration: none;
    color: #231F20;
    font-weight: 500;
    position: relative;
    text-align: center;
    font-size: 15px;
    
    &:not(:last-child) {
      border-bottom: 1px solid #e2e2e2;
    }
    
    &.selected {
      background-color: #e2e2e2;
    }
    
    &:hover {
      text-decoration: underline;
    }
  }
  
  .dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    box-sizing: border-box;
    display: none;
    border: 1px solid rgba(0,0,0,0.2);
    z-index: 1111;
    
    .dropdown-item {
      white-space: nowrap;
      padding: 12px;
      box-sizing: border-box;
      color: #231F20;
      background-color: white;
      text-decoration: none;
      display: block;
      
      &:not(:last-child) {
        border-bottom: 1px solid rgba(0,0,0,0.2);
      }
      
      &:hover {
        background-color: #e3e3e3;
        text-decoration: underline;
      }
    }
  }
`;
