import styled, { keyframes, css } from "styled-components";

const loading = keyframes`
  from {
    background-position: 0 0;
  }

  to {
    background-position: 100% 100%;
  }
`;

export const Form = styled.form`
    max-width: 320px;
    width: 100%;
    border: 1px solid #f8f8f8;
    background-color: white;
    
    &::before {
      height: 6px;
      content: '';
      display: block;
      background: #ffd200;
    }
    &[aria-busy='true']::before {
      background-size: 50% auto;
      animation: ${loading} 0.5s linear infinite;
    }
    
    ${props =>
    props.loader &&
    css`
        &:before {
            background: linear-gradient(
                to right,
                #ffd200 0%,
                #fff2e1 50%,
                #ffd200 100%
              );
        }`
    };
    
    ${props => 
        props.error && 
        css`
        &:before {
            background: red;
        }`
    };
    
    ${props =>
    props.success &&
    css`
        &:before {
            background: #00c100;
        }`
    };
    
    .header {
        text-align: center;
        font-size: 24px;
        font-weight: bold;
        padding: 12px 24px;
    }
    
    input {
        margin-bottom: 16px;
    }
    
    fieldset {
        margin: 0;
        padding: 8px 24px;
        border: none;
        
        &[disabled] {
          opacity: 0.5;
        }
    }
    
    .auth-btn {
        background-color: #ffd200;
        padding: 24px 32px;
        border: none;
        width: 100%;
        box-sizing: border-box;
        text-transform: uppercase;
        font-weight: bold;
        cursor: pointer;
        color: #231F20;
        
        :hover {
          background-color: #e5b900;
          transition: background-color .2s ease-in-out;
        }
        
        &[disabled] {
          opacity: 0.5;
        }
    }
`;

export const SimpleForm = styled.form`
    & > div {
      margin-bottom: 16px;
    }
`;