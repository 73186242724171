const CryptoJS = require("crypto-js");
const base64 = require("base-64");

export const signatureQuery = (message, code) => {
  const codeSHA = CryptoJS.SHA512(code).toString();
  const codeHmac = CryptoJS.HmacSHA512(message, codeSHA).toString(
    CryptoJS.enc.Latin1
  );
  return base64.encode(codeHmac);
};

export function setCookie(name, value, options = {}) {
  options = {
    path: "/",
    ...options,
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie =
    encodeURIComponent(name) + "=" + encodeURIComponent(value);

  for (let optionKey in options) {
    updatedCookie += "; " + optionKey;
    let optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += "=" + optionValue;
    }
  }

  document.cookie = updatedCookie;
}

export function deleteCookie(name) {
  setCookie(name, "", {
    "max-age": -1,
  });
}

export function getCookie(name) {
  const match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"));
  if (match) return match[2];
}

export const renderGroupRoutes = (data) => {
  let arr = [];

  const createGroupsArr = (data) => {
    data.forEach((item) => {
      if (item.children.length === 0) {
        item.path && arr.push(item);
      } else {
        item.path && arr.push(item);
        createGroupsArr(item.children);
      }
    });
  };

  createGroupsArr(data);

  return arr;
};

export const clearPhoneMask = (str) => {
  return str.substring(2).replace(/[-(_)\s]/g, "");
};

export const isValidIP = (ip) => {
  const octets = ip.split(".");
  if (octets.length !== 4) {
    return false;
  }
  for (const octet of octets) {
    if (!/^\d+$/.test(octet)) {
      return false;
    }
    const value = parseInt(octet, 10);
    if (value < 0 || value > 255) {
      return false;
    }
  }
  return true;
};
