import styled from "styled-components";

export const InputContainer = styled.div`
    width: 100%;
    
    label {
        font-size: 12px;
        line-height: 24px;
    }
    
    input,
	textarea {
        width: 100%;
        box-sizing: border-box;
        padding: 12px 16px;
        border: 1px solid rgba(0,0,0,0.1);
        outline: none;
         
        &.input-error {
          border-color: red;
        }
        
        :hover {
          border-color: rgba(0,0,0,0.3);
          transition: border-color .2s ease-in-out;
        }
        
        :focus {
          border-color: rgba(0,0,0,0.3);
        }
    }
    
    span {
      color: red;
      font-size: 14px;
      padding: 0 6px;
    }
`;