import React, {useRef, useState} from 'react';
import Logo from "../../media/images/logo";
import {
    HeaderStyles,
    HeaderWrapperStyles,
    UserDropDownStyles,
    UserHeaderLogoStyles,
    UserHeaderStyles
} from "../styles/Header";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {signOut} from "../../redux/auth/action";
import iconUser from '../../media/images/header-auth-icon.svg';
import ArrowNav from "../ArrowNav/ArrowNav";
import {CSSTransition} from "react-transition-group";
import useOnClickOutside from "../../hooks/useOnClickOutside";

const Header = () => {
    const {name} = useSelector(state => state.auth);
    const [open, setOpen] = useState(false);
    const ref = useRef();
    const dispatch = useDispatch();

    useOnClickOutside(ref, () => setOpen(false))

    const handleSignOut = () => {
        setOpen(false);
        dispatch(signOut());
    };

    return (
        <HeaderWrapperStyles>
            <HeaderStyles>
                <Link className='header-logo' to='/'>
                    <Logo color={'#231F20'}/>
                </Link>
                <UserHeaderStyles ref={ref}>
                    <UserHeaderLogoStyles onClick={() => setOpen(!open)}>
                        <img src={iconUser} alt='logo user'/>
                        <ArrowNav open={open}/>
                    </UserHeaderLogoStyles>
                    <CSSTransition in={open} timeout={300} classNames='dropdown' unmountOnExit>
                        <UserDropDownStyles>
                            <div className='dropdown-item--header'>{name}</div>
                            <div className='dropdown-item' onClick={handleSignOut}>Выход</div>
                        </UserDropDownStyles>
                    </CSSTransition>
                </UserHeaderStyles>
            </HeaderStyles>
        </HeaderWrapperStyles>
    );
};

export default Header;