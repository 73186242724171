import {
    FAILURE_ADD_FORBIDDEN_NUMBER,
    FAILURE_DEL_FORBIDDEN_NUMBER,
    FAILURE_FORBIDDEN_NUMBERS_LIST,
    GET_FORBIDDEN_NUMBERS_TOTAL,
    REQUEST_ADD_FORBIDDEN_NUMBER,
    REQUEST_DEL_FORBIDDEN_NUMBER,
    REQUEST_FORBIDDEN_NUMBERS_LIST,
    SUCCESS_ADD_FORBIDDEN_NUMBER,
    SUCCESS_DEL_FORBIDDEN_NUMBER,
    SUCCESS_FORBIDDEN_NUMBERS_LIST
} from "./constants";

const initialState = {
    forbiddenNumberList: [],
    loader: false,
    error: null,
    total: null
}

const forbiddenList = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_ADD_FORBIDDEN_NUMBER:
        case REQUEST_FORBIDDEN_NUMBERS_LIST:
        case REQUEST_DEL_FORBIDDEN_NUMBER:
            return {
                ...state,
                loader: true,
                error: null
            }
        case SUCCESS_FORBIDDEN_NUMBERS_LIST:
            return {
                ...state,
                loader: false,
                forbiddenNumberList: action.payload.numbers,
            }
        case SUCCESS_ADD_FORBIDDEN_NUMBER:
        case SUCCESS_DEL_FORBIDDEN_NUMBER:
            return {
                ...state,
                loader: false
            }
        case GET_FORBIDDEN_NUMBERS_TOTAL:
            return {
                ...state,
                total: action.payload
            }
        case FAILURE_FORBIDDEN_NUMBERS_LIST:
        case FAILURE_ADD_FORBIDDEN_NUMBER:
        case FAILURE_DEL_FORBIDDEN_NUMBER:
            return {
                ...state,
                loader: false,
                error: action.payload
            }
        default:
            return state;
    }
};

export default forbiddenList;