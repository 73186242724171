import {
    addWhiteListIp,
    deleteWhiteListIp,
    getWhiteList,
    getWhiteListItem, getWhiteListTotal
} from "../../network/kaf-api";
import {
    FAILURE_WHITE_LIST, FAILURE_WHITE_LIST_ITEM, GET_WHITE_LIST_TOTAL,
    REQUEST_WHITE_LIST,
    REQUEST_WHITE_LIST_ITEM,
    SUCCESS_WHITE_LIST,
    SUCCESS_WHITE_LIST_ITEM
} from "./constants";

export const requestWhiteList = (login, code, count, lastId, array) => async dispatch => {
    dispatch({ type: REQUEST_WHITE_LIST });
    try {
        const response = await getWhiteList(login, code, count, lastId);

        if(response.data.code === 0) {
            if(array) {
                const newArray = array.concat(response.data.body.ips);
                dispatch({
                    type: SUCCESS_WHITE_LIST, payload: response.data.body ? {
                        ips: newArray
                    } : { ips: [] }
                });
            }else {
                dispatch({
                    type: SUCCESS_WHITE_LIST, payload: response.data.body ? {
                        ips: response.data.body.ips
                    } : { ips: [] }
                });
                dispatch(requestWhiteListTotal(login, code));
            }

        }else {
            dispatch({ type: FAILURE_WHITE_LIST, payload: { message: response.data.msg }});
        }
    }catch (e) {
        dispatch({ type: FAILURE_WHITE_LIST, payload: { message: 'Ошибка при загрузке IP адресов' }})
    }
};

export const requestWhiteListTotal = (login, code) => async dispatch => {
    try {
        const response = await getWhiteListTotal(login, code);

        dispatch({ type: GET_WHITE_LIST_TOTAL, payload: response.data.body.total });
    }catch (e) {

    }
};

export const requestAddWhiteListIp = (login, code, data) => async dispatch => {
    try {
        const response = await addWhiteListIp(login, code, data);

        return response.data;
    }catch (e) {

    }
};

export const requestDelWhiteListIp = (login, code, ip) => async dispatch => {
    try {
        const response = await deleteWhiteListIp(login, code, ip);

        return response.data;
    }catch (e) {

    }
};

export const requestWhiteListItem = (login, code, ip) => async dispatch => {
    dispatch({ type: REQUEST_WHITE_LIST_ITEM });
    try {
        const response = await getWhiteListItem(login, code, ip);

        if(response.data.code === 0) {
            if(response.data.body) {
                dispatch({ type: SUCCESS_WHITE_LIST_ITEM, payload: response.data.body.ips });
            }else {
                dispatch({ type: FAILURE_WHITE_LIST_ITEM, payload: {message: 'Не удалось найти IP адрес'} });
            }
        }else {
            dispatch({ type: FAILURE_WHITE_LIST, payload: { message: response.data.msg }});
        }

    } catch (e) {

    }
};

export const clearWhiteListItem = () => dispatch => {
    dispatch({ type: SUCCESS_WHITE_LIST_ITEM, payload: null })
}