import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  initDefaultProfile,
  requestSetProfileData,
  requestUserProfile,
  updateProfileKaf,
} from "../redux/users/action";
import { useCookies } from "react-cookie";
import FraudProfileItem from "../components/FraudProfileItem";
import { ButtonStyle } from "../components/styles/Button";
import styled from "styled-components";
import FraudCheckbox from "../components/FraudCheckbox";
import Loader from "../components/Loader/Loader";
import plusIcon from "../media/images/plus-icon.svg";
import RangeInput from "../components/RangeInput";
import useModal from "../hooks/useModal";
import Modal from "../components/Modal/Modal";
import CreateProfileForm from "../containers/CreateProfileForm";
import { ErrorMessage } from "../components/styles/ErrorMessag";
import { CLEAR_PROFILE } from "../redux/users/constants";
import TrashIcon from "../media/images/trash-icon";
import DeleteUser from "../components/DeleteUser";
import EditPencil from "../components/EditPencil";
import UpdateUser from "../components/UpdateUser";
import BinCheckConfig from "../components/BinCheckConfig";
import AtmmConfig from "../components/AtmmConfig";
import ArammConfig from "../components/ArammConfig";
import TrafficScoreConfig from "../components/TrafficScoreConfig";

const HeaderUserProfileStyles = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  & > h2 {
    display: flex;
    align-items: center;
    gap: 6px;

    & > span {
      cursor: pointer;
    }
  }
`;

const ContainerUserProfileStyles = styled.div`
  & > label {
    margin-bottom: 16px;
  }

  & > div {
    margin-bottom: 16px;
  }
`;

const HeaderButtonsStyles = styled.div`
  display: flex;
  align-items: center;
  button {
    margin-left: 16px;
  }
`;

const UserProfilePage = ({ user }) => {
  const { userProfile, defaultUserProfile, loader, error } = useSelector(
    (state) => state.users
  );
  const [id, setId] = useState(null);
  const [modal, setModal, toggleModal] = useModal();
  const [delModal, setDelModal, toggleDelModal] = useModal();
  const [updateModal, setUpdateModal, toggleUpdateModal] = useModal();
  const [{ login, code }] = useCookies();
  const dispatch = useDispatch();

  const delModalOpen = (e, id) => {
    toggleDelModal();
    setId(id);
    e.preventDefault();
  };

  useEffect(() => {
    if (user) {
      dispatch(requestUserProfile(login, code, user.merchantID));
    }
    //eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    return () => {
      dispatch({ type: CLEAR_PROFILE });
    };
    //eslint-disable-next-line
  }, []);

  const defaultProfValues = () => {
    dispatch(initDefaultProfile());
  };

  const updateStrictness = (e) => {
    const name = e.target.name;
    const value = Number(e.target.value);
    dispatch(updateProfileKaf(value, name));
  };

  const updateFraudCheckbox = (e) => {
    const name = e.target.name;
    const value = e.target.checked;
    dispatch(updateProfileKaf(value, name));
  };

  const saveData = () => {
    dispatch(requestSetProfileData(login, code, user.merchantID, userProfile));
  };

  return (
    <div>
      {loader && <Loader />}
      {error && <ErrorMessage>{error.message}</ErrorMessage>}
      {userProfile && (
        <>
          <Modal
            isShowing={modal}
            hide={() => setModal(false)}
            title="Добавить профиль"
          >
            <CreateProfileForm user={user} hide={() => setModal(false)} />
          </Modal>
          <Modal
            isShowing={delModal}
            hide={() => setDelModal(false)}
            title="Удалить клиента"
          >
            <DeleteUser
              hide={() => setDelModal(false)}
              id={id}
              currentUser={true}
            />
          </Modal>
          <Modal
            isShowing={updateModal}
            hide={() => setUpdateModal(false)}
            title="Изменить клиента"
          >
            <UpdateUser user={user} hide={() => setUpdateModal(false)} />
          </Modal>
          <HeaderUserProfileStyles>
            <h2>{user.name}</h2>
            <HeaderButtonsStyles>
              <ButtonStyle onClick={toggleModal}>
                Создать профиль <img src={plusIcon} alt="plus icon" />
              </ButtonStyle>
              <ButtonStyle onClick={toggleUpdateModal}>
                <EditPencil size={14} color={"#000"} />
                Изменить клиента
              </ButtonStyle>
              <ButtonStyle onClick={(e) => delModalOpen(e, user.id)}>
                Удалить клиента
                <TrashIcon size={14} color="#000" />
              </ButtonStyle>
            </HeaderButtonsStyles>
            {defaultUserProfile !== userProfile && (
              <HeaderButtonsStyles>
                <ButtonStyle onClick={saveData}>Сохранить</ButtonStyle>
                <ButtonStyle onClick={defaultProfValues}>Отменить</ButtonStyle>
              </HeaderButtonsStyles>
            )}
          </HeaderUserProfileStyles>
          <ContainerUserProfileStyles>
            <FraudCheckbox
              checked={userProfile.kaf}
              title="kaf"
              name="kaf"
              value={userProfile.kaf}
              onChange={(e) => updateFraudCheckbox(e)}
            />
            <FraudCheckbox
              checked={userProfile.ipQs}
              title="ipQs"
              name="ipQs"
              value={userProfile.ipQs}
              onChange={(e) => updateFraudCheckbox(e)}
            />
            <FraudCheckbox
              checked={userProfile.parent ? userProfile.parent : false}
              title="parent"
              name="parent"
              value={userProfile.parent ? userProfile.parent : false}
              onChange={(e) => updateFraudCheckbox(e)}
            />
            <AtmmConfig userProfile={userProfile} />
            <ArammConfig userProfile={userProfile} />
            <BinCheckConfig userProfile={userProfile} />
            <TrafficScoreConfig userProfile={userProfile} />
            <RangeInput
              value={userProfile.strictness}
              onChange={(e) => updateStrictness(e)}
              name="strictness"
              title={`strictness - ${userProfile.strictness}`}
              max={3}
              min={0}
            />
            {userProfile.profiles &&
              userProfile.profiles.map((item, index) => (
                <FraudProfileItem key={index} index={index} item={item} />
              ))}
          </ContainerUserProfileStyles>
        </>
      )}
    </div>
  );
};

export default UserProfilePage;
