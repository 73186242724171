import React from "react";
import styled, { css } from 'styled-components';

const ArrowNavStyles = styled.svg`
    transition: all .2s ease-in-out;
    stroke: #8F8D8E;
    
    ${props => props.open && css`
        transition: all .2s ease-in-out;
        transform: rotate(180deg);
        stroke: #231F20;
    `}    
}`;

const ArrowNav = ({open, header}) => {

    return (

            header ? <ArrowNavStyles open={open} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M5.45 7.4C5.11863 7.15147 4.64853 7.21863 4.4 7.55C4.15147 7.88137 4.21863 8.35147 4.55 8.6L5.45 7.4ZM9 11L8.55 11.6C8.81667 11.8 9.18333 11.8 9.45 11.6L9 11ZM13.45 8.6C13.7814 8.35147 13.8485 7.88137 13.6 7.55C13.3515 7.21863 12.8814 7.15147 12.55 7.4L13.45 8.6ZM4.55 8.6L8.55 11.6L9.45 10.4L5.45 7.4L4.55 8.6ZM9.45 11.6L13.45 8.6L12.55 7.4L8.55 10.4L9.45 11.6Z"
                        fill="#E6E6E6"/>
                </ArrowNavStyles>
                :
                <ArrowNavStyles open={open} width="14" height="9"
                     viewBox="0 0 14 9" fill="none">
                    <path d="M1 1L7 7L13 1" strokeWidth="1.5"/>
                </ArrowNavStyles>

    )
};

export default ArrowNav;