import React, {useEffect} from "react";
import {requestBlackList} from "../redux/blackList/actions";
import {useCookies} from "react-cookie";
import {useDispatch, useSelector} from "react-redux";
import {UsersHeaderStyles} from "../components/styles/Users";
import {TableContainerStyles, TableStyle} from "../components/styles/Table";
import Loader from "../components/Loader/Loader";
import {ErrorMessage} from "../components/styles/ErrorMessag";
import {Link} from "react-router-dom";
import {requestWhiteList} from "../redux/whiteList/action";
import {requestForbiddenNumbersList} from "../redux/forbiddenNumbers/action";

const MainPage = () => {
    const {permissions} = useSelector(state => state.auth);
    const blackList = useSelector(state => state.blackList);
    const whiteList = useSelector(state => state.whiteList);
    const forbiddenList = useSelector(state => state.forbiddenList);
    const [{login, code}] = useCookies();
    const dispatch = useDispatch();

    useEffect(() => {
        if(permissions) {
            permissions.find(item => item === 'black-list') && dispatch(requestBlackList(login, code, 0, 20));
            permissions.find(item => item === 'white-list') && dispatch(requestWhiteList(login, code, 0, 20));
            permissions.find(item => item === 'forbidden-numbers') && dispatch(requestForbiddenNumbersList({login, code, data: {from: 0, to: 20}}));
        }
        //eslint-disable-next-line
    }, []);



    return (
        <div>
            {blackList.error && <ErrorMessage>{blackList.error.message}</ErrorMessage>}
            {blackList.loader && <Loader/>}
            <UsersHeaderStyles><h2>Общая статистика</h2></UsersHeaderStyles>
            <TableContainerStyles>
                <TableStyle mainPage>
                    <div className='table-header-item'>Показатель</div>
                    <div className='table-header-item table-item--center'>Количество</div>
                </TableStyle>
                {blackList.total && <TableStyle mainPage>
                    <div className='table-item'><Link to={'/black-list'}>Общее число запрещенных адресов</Link></div>
                    <div className='table-item table-item--bold table-item--center'>{blackList.total}</div>
                </TableStyle>}
                {whiteList.total && <TableStyle mainPage>
                    <div className='table-item'><Link to={'/white-list'}>Общее число разрешенных адресов</Link></div>
                    <div className='table-item table-item--bold table-item--center'>{whiteList.total}</div>
                </TableStyle>}
                {forbiddenList.total && <TableStyle mainPage>
                    <div className='table-item'><Link to={'/forbidden-numbers'}>Общее число запрещенных номеров</Link></div>
                    <div className='table-item table-item--bold table-item--center'>{forbiddenList.total}</div>
                </TableStyle>}
            </TableContainerStyles>
        </div>
    )
};

export default MainPage;