import React from 'react';

const TrashIcon = ({color, size}) => {
    return (
        <svg width={`${size}px`} height={`${size}px`} viewBox="0 0 24 24" fill="none" stroke={color} strokeWidth="2"
             strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash">
            <polyline points="3 6 5 6 21 6" />
            <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
        </svg>
    )
};

export default TrashIcon;
