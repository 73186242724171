import {NavLink} from "react-router-dom";
import React from "react";
import {useSelector} from "react-redux";

const PermNavLink = ({ to, activeClassName, className, children }) => {
    const {permissions} = useSelector(state => state.auth);

    return (permissions && permissions.find(item => item === to.replace(/\//g, '')) ?
        <NavLink to={to} className={className} activeClassName={activeClassName}>
            {children}
        </NavLink> : ''
    )
};

export default PermNavLink;