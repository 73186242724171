import React from "react";

const Logo = ({color}) => {
    return (
        <svg viewBox="0 0 74 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.53216 3.71179C0.415808 3.71179 -0.756329 6.12118 0.546044 7.78171L12.2348 22.8241C13.2442 24.1265 15.2303 24.1265 16.2396 22.8241L27.9284 7.78171C29.2308 6.12118 28.0261 3.71179 25.9423 3.71179H2.53216Z"
                fill="#FFCD00"/>
            <path
                d="M2.40138 10.1911C3.76888 4.98158 8.55509 1.10702 14.253 1.10702C19.9509 1.10702 24.7371 4.94902 26.1046 10.1911L26.9511 9.11662C25.1929 3.84201 20.1788 0 14.253 0C8.32718 0 3.31304 3.84201 1.55484 9.11662L2.40138 10.1911Z"
                fill="#1D1D1B"/>
            <path
                d="M12.235 22.8567C13.2443 24.1591 15.2304 24.1591 16.2398 22.8567L26.1053 10.1911C24.7378 4.98162 19.9515 1.10706 14.2537 1.10706C8.55578 1.10706 3.76956 4.94906 2.40207 10.1911L12.235 22.8567Z"
                fill="#FFED00"/>
            <path d="M18.1931 10.5492V12.3726H15.2953V18.6891H13.1464V12.3726H10.2486V10.5492H18.1931Z" fill="#1D1D1B"/>
            <path d="M18.2257 7.8143H10.2812V9.63761H18.2257V7.8143Z" fill="#1D1D1B"/>
            <path
                d="M3.57419 11.6888C4.35561 6.54443 8.81623 2.53964 14.2536 2.53964C19.691 2.53964 24.1517 6.51187 24.9331 11.6888L25.5517 10.8748C24.4447 5.69789 19.8213 1.79077 14.2536 1.79077C8.686 1.79077 4.06257 5.69789 2.95555 10.8748L3.57419 11.6888Z"
                fill="#1D1D1B"/>
            <path
                d="M33.7241 10.7115C33.7892 10.6464 33.8543 10.6138 33.952 10.6138H34.8962C34.9939 10.6138 35.059 10.6464 35.1242 10.7115C35.1893 10.7766 35.2218 10.8417 35.2218 10.9394V15.2047L37.0126 12.9907C37.1103 12.8604 37.2405 12.8279 37.3708 12.8279H38.5429C38.6406 12.8279 38.7057 12.8279 38.7383 12.893C38.7708 12.9255 38.8034 12.9907 38.8034 13.0232C38.8034 13.0558 38.7708 13.1535 38.7057 13.2186L36.7521 15.4977L38.8359 18.135C38.901 18.2001 38.9336 18.2653 38.9336 18.3304C38.9336 18.3955 38.901 18.4281 38.8685 18.4606C38.8359 18.4932 38.7382 18.5257 38.6731 18.5257H37.5336C37.3708 18.5257 37.2405 18.4606 37.1428 18.3304L35.7754 16.6373L35.2218 17.2885V18.2001C35.2218 18.2978 35.1893 18.363 35.1242 18.4281C35.059 18.4932 34.9939 18.5257 34.8962 18.5257H33.952C33.8543 18.5257 33.7892 18.4932 33.7241 18.4281C33.659 18.363 33.6264 18.2978 33.6264 18.2001V10.972C33.659 10.8417 33.659 10.7441 33.7241 10.7115Z"
                fill={color}/>
            <path
                d="M44.3384 18.3961C44.2732 18.331 44.2407 18.2659 44.2407 18.1682V17.9077C44.1104 18.1031 43.8825 18.2659 43.5569 18.3961C43.2313 18.5264 42.8732 18.5915 42.515 18.5915C41.929 18.5915 41.408 18.4612 40.9847 18.2008C40.5615 17.9403 40.2033 17.5821 39.9754 17.1263C39.7475 16.6705 39.6172 16.1821 39.6172 15.6286C39.6172 15.0751 39.7475 14.5541 39.9754 14.0983C40.2033 13.6425 40.5289 13.2843 40.9522 13.0238C41.3755 12.7634 41.8964 12.6331 42.4825 12.6331C42.8732 12.6331 43.1988 12.6982 43.5244 12.8285C43.8174 12.9587 44.0779 13.1215 44.2407 13.3494V13.0564C44.2407 12.9587 44.2732 12.8936 44.3384 12.8285C44.4035 12.7633 44.4686 12.7308 44.5663 12.7308H45.4779C45.5756 12.7308 45.6407 12.7633 45.7058 12.8285C45.771 12.8936 45.8035 12.9587 45.8035 13.0564V18.1682C45.8035 18.2659 45.771 18.331 45.7058 18.3961C45.6407 18.4612 45.5756 18.4938 45.4779 18.4938H44.5663C44.4686 18.4938 44.4035 18.4612 44.3384 18.3961ZM43.8825 14.4564C43.5895 14.1634 43.2313 14.0006 42.7429 14.0006C42.2546 14.0006 41.8964 14.1634 41.6034 14.4564C41.3103 14.7495 41.1801 15.1402 41.1801 15.6286C41.1801 16.117 41.3429 16.4751 41.6034 16.8007C41.8964 17.0937 42.2871 17.2565 42.7429 17.2565C43.2313 17.2565 43.622 17.1263 43.8825 16.8333C44.1756 16.5402 44.3058 16.1495 44.3058 15.6611C44.3058 15.1402 44.1756 14.7495 43.8825 14.4564Z"
                fill={color}/>
            <path
                d="M47.2686 17.9077C47.171 17.8425 47.1384 17.7774 47.1384 17.6797C47.1384 17.6146 47.171 17.5495 47.2035 17.4844L47.6594 16.8983C47.7245 16.8006 47.8222 16.7681 47.8873 16.7681C47.9524 16.7681 48.0175 16.7681 48.0826 16.8332C48.3431 16.9634 48.6036 17.0937 48.8966 17.1588C49.1896 17.2564 49.4501 17.289 49.7106 17.289C49.9385 17.289 50.1339 17.2239 50.2967 17.1262C50.4595 17.0285 50.5246 16.8983 50.5246 16.7681C50.5246 16.6378 50.4595 16.5402 50.2967 16.475C50.1339 16.4099 49.9711 16.3774 49.7432 16.3122L49.4176 16.2797C48.9292 16.2146 48.571 16.1169 48.278 16.0192C47.985 15.9215 47.757 15.7587 47.5291 15.5308C47.2687 15.2703 47.171 14.9448 47.171 14.4889C47.171 13.8377 47.3989 13.3819 47.8873 13.0889C48.3431 12.8284 48.8966 12.6656 49.5478 12.6656C49.9385 12.6656 50.3292 12.7307 50.6548 12.8284C50.9804 12.9261 51.3386 13.0889 51.6642 13.3168C51.7618 13.3819 51.827 13.447 51.827 13.5447C51.827 13.6098 51.7944 13.6749 51.7293 13.74L51.306 14.3261C51.2409 14.4238 51.1432 14.4564 51.0781 14.4564C51.013 14.4564 50.9479 14.4563 50.8827 14.3912C50.3943 14.1308 49.9385 14.0005 49.5152 14.0005C49.3199 14.0005 49.1571 14.0331 48.9943 14.1308C48.8315 14.2284 48.7664 14.3261 48.7664 14.4238C48.7664 14.5866 48.8641 14.6843 49.0594 14.7494C49.2548 14.8145 49.5152 14.8796 49.8734 14.9122C50.4269 15.0099 50.8502 15.1075 51.1107 15.2052C51.3711 15.3029 51.599 15.4657 51.827 15.6936C52.0549 15.9541 52.1525 16.3122 52.1525 16.7355C52.1525 17.3216 51.9572 17.7774 51.5339 18.103C51.1106 18.4286 50.5571 18.5914 49.8083 18.5914C48.7664 18.6239 47.9524 18.3635 47.2686 17.9077Z"
                fill={color}/>
            <path
                d="M53.1944 17.9077C53.0968 17.8425 53.0642 17.7774 53.0642 17.6797C53.0642 17.6146 53.0968 17.5495 53.1293 17.4844L53.5852 16.8983C53.6503 16.8006 53.748 16.7681 53.8131 16.7681C53.8782 16.7681 53.9433 16.7681 54.0084 16.8332C54.2689 16.9634 54.5294 17.0937 54.8224 17.1588C55.1154 17.2564 55.3759 17.289 55.6364 17.289C55.8643 17.289 56.0597 17.2239 56.2225 17.1262C56.3853 17.0285 56.4504 16.8983 56.4504 16.7681C56.4504 16.6378 56.3853 16.5402 56.2225 16.475C56.0597 16.4099 55.8969 16.3774 55.669 16.3122L55.3434 16.2797C54.855 16.2146 54.4968 16.1169 54.2038 16.0192C53.9108 15.9215 53.6828 15.7587 53.4549 15.5308C53.1944 15.2703 53.0968 14.9448 53.0968 14.4889C53.0968 13.8377 53.3247 13.3819 53.8131 13.0889C54.2689 12.8284 54.8224 12.6656 55.4736 12.6656C55.8643 12.6656 56.255 12.7307 56.5806 12.8284C56.9062 12.9261 57.2643 13.0889 57.5899 13.3168C57.6876 13.3819 57.7527 13.447 57.7527 13.5447C57.7527 13.6098 57.7202 13.6749 57.6551 13.74L57.2318 14.3261C57.1667 14.4238 57.069 14.4564 57.0039 14.4564C56.9388 14.4564 56.8736 14.4563 56.8085 14.3912C56.3201 14.1308 55.8643 14.0005 55.441 14.0005C55.2457 14.0005 55.0829 14.0331 54.9201 14.1308C54.7573 14.2284 54.6922 14.3261 54.6922 14.4238C54.6922 14.5866 54.7898 14.6843 54.9852 14.7494C55.1806 14.8145 55.441 14.8796 55.7992 14.9122C56.3527 15.0099 56.776 15.1075 57.0365 15.2052C57.2969 15.3029 57.5248 15.4657 57.7527 15.6936C57.9807 15.9541 58.0783 16.3122 58.0783 16.7355C58.0783 17.3216 57.883 17.7774 57.4597 18.103C57.0364 18.4286 56.4829 18.5914 55.7341 18.5914C54.7247 18.6239 53.9107 18.3635 53.1944 17.9077Z"
                fill={color}/>
            <path
                d="M63.646 18.3961C63.5808 18.331 63.5483 18.2659 63.5483 18.1682V17.9077C63.4181 18.1031 63.1901 18.2659 62.8645 18.3961C62.5389 18.5264 62.1808 18.5915 61.8226 18.5915C61.2366 18.5915 60.7156 18.4612 60.2924 18.2008C59.8691 17.9403 59.5109 17.5821 59.283 17.1263C59.0551 16.6705 58.9249 16.1821 58.9249 15.6286C58.9249 15.0751 59.0551 14.5541 59.283 14.0983C59.5109 13.6425 59.8365 13.2843 60.2598 13.0238C60.6831 12.7634 61.204 12.6331 61.7901 12.6331C62.1808 12.6331 62.5064 12.6982 62.832 12.8285C63.125 12.9587 63.3855 13.1215 63.5483 13.3494V13.0564C63.5483 12.9587 63.5808 12.8936 63.646 12.8285C63.7111 12.7633 63.7762 12.7308 63.8739 12.7308H64.7855C64.8832 12.7308 64.9483 12.7633 65.0135 12.8285C65.0786 12.8936 65.1111 12.9587 65.1111 13.0564V18.1682C65.1111 18.2659 65.0786 18.331 65.0135 18.3961C64.9483 18.4612 64.8832 18.4938 64.7855 18.4938H63.8739C63.7762 18.4938 63.7111 18.4612 63.646 18.3961ZM63.1901 14.4564C62.8971 14.1634 62.5389 14.0006 62.0506 14.0006C61.5622 14.0006 61.204 14.1634 60.911 14.4564C60.6179 14.7495 60.4877 15.1402 60.4877 15.6286C60.4877 16.117 60.6505 16.4751 60.911 16.8007C61.204 17.0937 61.5947 17.2565 62.0506 17.2565C62.5389 17.2565 62.9297 17.1263 63.1901 16.8333C63.4832 16.5402 63.6134 16.1495 63.6134 15.6611C63.646 15.1402 63.4832 14.7495 63.1901 14.4564Z"
                fill={color}/>
            <path
                d="M69.5067 14.2284C69.5393 14.261 69.5718 14.2935 69.5718 14.3587V14.8145C69.5718 14.8796 69.5718 14.9122 69.5067 14.9447C69.4742 14.9773 69.4416 15.0099 69.3765 15.0099H66.5113C66.4461 15.0099 66.4136 15.0099 66.381 14.9447C66.3485 14.9122 66.3159 14.8796 66.3159 14.8145V14.2284C66.3159 14.1307 66.3485 14.0656 66.4136 14.0005L66.9997 13.5121L67.9113 12.7633C68.1392 12.5679 68.302 12.4377 68.3671 12.3074C68.4323 12.1772 68.4974 12.047 68.4974 11.8842C68.4974 11.6888 68.4323 11.5586 68.302 11.4609C68.1718 11.3632 68.009 11.3307 67.8462 11.3307C67.5532 11.3307 67.3252 11.4934 67.195 11.8516C67.1624 11.9493 67.0973 11.9818 67.0322 11.9818C67.0322 11.9818 66.9997 11.9818 66.9671 11.9818L66.381 11.8516C66.2833 11.819 66.2508 11.7865 66.2508 11.6888C66.2508 11.6562 66.2508 11.6237 66.2508 11.6237C66.3159 11.4609 66.4136 11.2655 66.5438 11.1353C66.6741 10.9725 66.8369 10.8423 66.9997 10.712C67.2276 10.5818 67.488 10.4841 67.8136 10.4841C68.1392 10.4841 68.4323 10.5492 68.6927 10.6795C68.9532 10.8097 69.116 11.0051 69.2463 11.2004C69.3765 11.4283 69.4416 11.6237 69.4416 11.8842C69.4416 12.1772 69.3439 12.4377 69.1811 12.6982C69.0183 12.9586 68.7579 13.2191 68.4648 13.4796L68.302 13.7075L68.0416 13.9028C67.9113 14.0005 67.7811 14.0982 67.6834 14.1959H69.4091C69.4416 14.1959 69.4742 14.1959 69.5067 14.2284Z"
                fill={color}/>
            <path
                d="M73.9349 13.2841C73.9674 13.3167 74 13.3492 74 13.4143V13.8702C74 13.9353 74 13.9679 73.9349 14.0004C73.9023 14.033 73.8697 14.0655 73.8046 14.0655H73.4465V14.847C73.4465 14.9121 73.4465 14.9446 73.3813 14.9772C73.3488 15.0098 73.3162 15.0423 73.2511 15.0423H72.6651C72.5999 15.0423 72.5674 15.0423 72.5348 14.9772C72.5022 14.9446 72.4697 14.9121 72.4697 14.847V14.0655H70.4836C70.4185 14.0655 70.3859 14.0655 70.3533 14.0004C70.3208 13.9679 70.2882 13.9353 70.2882 13.8702V13.4143C70.2882 13.3167 70.3208 13.2516 70.3859 13.1864L72.5999 10.6794C72.6651 10.6143 72.7302 10.5817 72.8279 10.5817H73.2511C73.3162 10.5817 73.3488 10.5817 73.3813 10.6468C73.4139 10.6794 73.4465 10.7119 73.4465 10.777V13.2515H73.8046C73.8372 13.219 73.9023 13.2516 73.9349 13.2841ZM71.558 13.219H72.4697V12.1445L71.558 13.219Z"
                fill={color}/>
        </svg>
    )
};

export default Logo;

