import {
    FAILURE_BLACK_LIST,
    FAILURE_BLACK_LIST_ITEM, GET_BLACK_LIST_TOTAL,
    REQUEST_BLACK_LIST,
    REQUEST_BLACK_LIST_ITEM,
    SUCCESS_BLACK_LIST, SUCCESS_BLACK_LIST_ITEM
} from "./constants";

const initialState = {
    blackList: [],
    error: null,
    loader: false,
    total: null,
    blackListItem: null
};

const blackList = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_BLACK_LIST:
        case REQUEST_BLACK_LIST_ITEM:
            return {
                ...state,
                loader: true,
                error: null
            };
        case SUCCESS_BLACK_LIST:
            return {
                ...state,
                blackList: action.payload.ips,
                loader: false
            }
        case SUCCESS_BLACK_LIST_ITEM:
            return {
                ...state,
                blackListItem: action.payload,
                loader: false
            }
        case GET_BLACK_LIST_TOTAL:
            return {
                ...state,
                total: action.payload
            }
        case FAILURE_BLACK_LIST:
        case FAILURE_BLACK_LIST_ITEM:
            return {
                ...state,
                error: action.payload,
                loader: false
            }
        default:
            return state;
    }
};

export default blackList;