import styled from 'styled-components';

export const SelectStyles = styled.select`
  width: 100%;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  padding: 6px 12px;
  border: none;
  outline: none;
`;

export const SelectContainerStyles = styled.div`
    label {
      font-size: 12px;
      line-height: 24px;
    }
    
    span {
      color: red;
      font-size: 14px;
      padding: 0 6px;
    }
`;

export const SimpleSelectContainerStyles = styled.div`
      border: none;
      outline: none;
      
      & > .label {
        font-size: 12px;
        line-height: 24px;
      }
`;

export const SimpleSelectFieldStyles = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding: 12px 16px;
    border: 1px solid rgba(0,0,0,0.1);
    outline: none;
    position: relative;
    height: 41px;
         
    &.input-error {
      border-color: red;
    }
        
    :hover {
      border-color: rgba(0,0,0,0.3);
      transition: border-color .2s ease-in-out;
    }
        
    :focus {
      border-color: rgba(0,0,0,0.3);
    }
    
    & > p > span {
      left: 95%;
    }
`;

export const SimpleSelectListStyles = styled.div`
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    border: 1px solid #dbdbdb;
`;

export const SimpleSelectValueStyles = styled.p`
    font: 400 13.3333px Arial;
`;

export const SimpleSelectItemStyles = styled.div`
    padding: 4px 8px;
    box-sizing: border-box;
    cursor: pointer;
    
    &:not(:last-child) {
      border-bottom: 1px solid #dbdbdb;
    }
    
    &:hover {
      background-color: #dbdbdb;
    }
    
`;