import {SIGN_IN_FAILURE, SIGN_IN_REQUEST, SIGN_IN_SUCCESS, SIGN_OUT} from "./constants";
import Cookies from 'js-cookie'

const initialState = {
    isAuth: !!(Cookies.get('login') && Cookies.get('code')),
    name: localStorage.getItem('name'),
    permissions: JSON.parse(localStorage.getItem('permissions')) || null,
    loader: false,
    error: null
};

const auth = (state = initialState, { type, payload }) => {
    switch (type) {
        case SIGN_IN_REQUEST:
            return {
                ...state,
                loader: true,
                error: false,
                isAuth: false
            }
        case SIGN_IN_SUCCESS:
            Cookies.set('login', payload.login);
            Cookies.set('code', payload.code);
            localStorage.setItem('name', payload.name);
            localStorage.setItem('permissions', JSON.stringify(payload.permissions));
            return {
                ...state,
                loader: false,
                isAuth: true,
                name: payload.name,
                permissions: payload.permissions
            }
        case SIGN_IN_FAILURE:
            return {
                ...state,
                loader: false,
                error: payload
            }
        case SIGN_OUT:
            Cookies.remove('login');
            Cookies.remove('code');
            localStorage.removeItem('session');
            return {
                ...state,
                loader: false,
                isAuth: false
            }
        default:
            return state;
    }
};

export default auth;