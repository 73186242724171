import React from "react";
import { ControlStyles } from "../components/styles/Table";
import Button from "../components/Button/Button";
import { useForm, Controller } from "react-hook-form";
import Input from "../components/Input/Input";

const AddForbiddenNumbers = ({ addNumber }) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid },
  } = useForm({ mode: "onChange" });

  const onHandleChange = (onChange, e) => {
    onChange(e.target.value.replace(/_/g, ""));
  };

  const onSubmit = (data) => {
    addNumber(data.number);
    reset({ number: "" });
  };

  const customRules = (value) => {
    let isValid = true;
    let message;
    if (value.length < 1 || value.length > 16) {
      isValid = false;
      message = "Неккоректный номер";
    }
    return isValid || message;
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ControlStyles>
          <Controller
            name="number"
            rules={{ validate: (value) => customRules(value) }}
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }) => (
              <Input
                value={value}
                placeholder="Введите номер"
                onChange={(e) => onHandleChange(onChange, e)}
              />
            )}
          />
          <Button disabled={!isValid}>Добавить</Button>
        </ControlStyles>
      </form>
    </div>
  );
};

export default AddForbiddenNumbers;
