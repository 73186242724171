import React, {useEffect, useState} from "react";
import {SimpleForm} from "../components/styles/Form";
import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import Input from "../components/Input";
import Button from "../components/Button/Button";
import {ErrorMessage} from "../components/styles/ErrorMessag";
import {requestMakeUser} from "../redux/users/action";
import {useCookies} from "react-cookie";
import SimpleSelect from "../components/SimpleSelect/SimpleSelect";
import {SimpleSelectItemStyles} from "../components/styles/Select";

const MakeClientForm = ({hide}) => {
    const {users, error} = useSelector(state => state.users);
    const [{login, code}] = useCookies();
    const {register, handleSubmit, setValue, formState: {errors}} = useForm();
    const [user, setUser] = useState(null);
    const dispatch = useDispatch();

    const onSubmit = (data) => {
        let newData = data;

        if(user) {
            newData.IpQSKey = user.IpQSKey;
            newData.parentID = user.id;
        }
        dispatch(requestMakeUser({ login, code, data: newData }))
            .then(res => res.code === 0 && hide());
    };

    const setSelectUser = (e, item) => {
        setUser(item);
    };

    const clearUser = (e) => {
        e.stopPropagation();
        setUser(null)
    }

    useEffect(() => {
        if(user) {
            setValue('IpQSKey', user.IpQSKey);
        }
        //eslint-disable-next-line
    }, [user]);

    return (
        <SimpleForm onSubmit={handleSubmit(onSubmit)}>
            <Input {...register('name', {
                required: 'Введите имя клиента'
            })} label='Имя клиента'
                   errorMessage={errors.name && errors.name.message}/>
                   <SimpleSelect label='Выберите мерчанта' value={user && user.name} clear={clearUser}>
                       {users.length > 0 && users.map(user => {
                           return <SimpleSelectItemStyles onClick={(e) => setSelectUser(e, user)} key={user.id} >{user.name}</SimpleSelectItemStyles>
                       })}
                   </SimpleSelect>
            <Input {...register('merchantID',
                {
                    required: 'Введите Merchant ID'
                })}
                            label='Merchant ID'
                            errorMessage={errors.merchantID && errors.merchantID.message}/>
            <Input {...register('IpQSKey',
                {
                    required: 'Введите IpQSKey'
                })}
                   label='IpQSKey'
                   errorMessage={errors.IpQSKey && errors.IpQSKey.message}/>
            {error && <ErrorMessage role='alert'>{error.message}</ErrorMessage>}
            <Button formStyle>Добавить</Button>
        </SimpleForm>
    )
};

export default MakeClientForm;