import styled from "styled-components";

export const RangeInputStyles = styled.input`
  -webkit-appearance: none;
  width: 100%;
  height: 12px;
  background: #e3e3e3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: all 0.2s;
  box-sizing: border-box;
  margin: 0;

  &:hover {
    opacity: 1;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    background: #ffd200;
    cursor: pointer;
  }

  &::-moz-range-thumb {
    width: 16px;
    height: 16px;
    background: #04aa6d;
    cursor: pointer;
  }
`;

export const FraudCheckboxStyles = styled.label`
  display: block;
  width: 100%;

  ${(props) =>
    props.disabled ? "background: #dddddd;" : "background: #ffffff;"}

  & > input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkmark-container {
      background-color: #ffd200;
      transition: all 0.2s ease-in-out;

      & > .checkmark {
        border: 1px solid #ffd200;

        &::after {
          display: block;
          left: 4px;
          top: 0;
          width: 5px;
          height: 10px;
          border: solid black;
          border-width: 0 3px 3px 0;
          transform: rotate(45deg);
        }
      }
    }
  }

  & > .checkmark-container {
    width: 100%;
    cursor: pointer;
    padding: 9px 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.2s ease-in-out;

    & p {
      margin: 0;
    }

    & > .checkmark {
      position: relative;
      ${(props) =>
        props.disabled ? "border: none;" : "border: 1px solid black;"}
      width: 16px;
      height: 16px;

      &::after {
        content: "";
        position: absolute;
        display: none;
      }
    }
  }
`;
