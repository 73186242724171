import styled from 'styled-components';

export const UsersHeaderStyles = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
  
  & > h2 > a {
    margin-left: 8px;
    vertical-align: middle;
  }
`;