import React from 'react';
import {SelectContainerStyles, SelectStyles} from "../styles/Select";

const Select = React.forwardRef(({ label, children, errorMessage, ...attrs }, ref) => {
    return (
        <SelectContainerStyles>
            {label && <label>{label}</label>}
            <SelectStyles {...attrs} ref={ref}>
                {children}
            </SelectStyles>
            {errorMessage && <span role="alert">{errorMessage}</span>}
        </SelectContainerStyles>
    );
});

export default Select;