import axios from 'axios';
import {signatureQuery} from "../utils/helpers";
import {API_PLUS_KAF} from "../constants/api-contstants";

export const sendQuery = (message, code) => {
    message = JSON.stringify(Object.assign(message));
    return axios.post(
        API_PLUS_KAF,
        message,
        {
            headers: {
                'Content-Type': 'application/json',
                Sign: signatureQuery(message, code),
            },
        },
    );
};

export const sendQueryUploadFile = (request, file, code) => {
    const data = new FormData();
    data.append('request', JSON.stringify(request));
    data.append('file', file);

    return axios.post(
        API_PLUS_KAF,
        data,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
                Sign: signatureQuery(request, code),
            },
        },
    );
}