import {sendQuery, sendQueryUploadFile} from "./queries";
import CryptoJS from 'crypto-js';

export const userAuth = (login, code) => {
    const message = {
        type: 'user.auth',
        lgn: login,
        pass: code,
    };

    return sendQuery(message, CryptoJS.MD5(code).toString());
};

export const getWhiteList = (login, code, count, lastId) => {
    const message = {
        type: 'whiteList.getIPs',
        lgn: login,
        body: {
            count, lastId
        }
    };

    return sendQuery(message, code);
};

export const getWhiteListTotal = (login, code) => {
    const message = {
        type: 'whiteList.total',
        lgn: login
    };

    return sendQuery(message, code);
}

export const addWhiteListIp = (login, code, data) => {
    const message = {
        type: 'whiteList.addIP',
        lgn: login,
        body: data
    };

    return sendQuery(message, code)
};

export const deleteWhiteListIp = (login, code, ip) => {
    const message = {
        type: 'whiteList.delIP',
        lgn: login,
        body: {
            ip
        }
    };

    return sendQuery(message, code)
}

export const getBlackList = (login, code, count, lastId) => {
    const message = {
        type: 'blackList.getIPs',
        lgn: login,
        body: {
            count,
            lastId
        }
    };

    return sendQuery(message, code);
};

export const getBlackListTotal = (login, code) => {
    const message = {
        type: 'blackList.total',
        lgn: login
    };

    return sendQuery(message, code);
}

export const importBlackList = (login, file, code) => {
    const message = {
        type: 'blackList.import',
        lgn: login
    };

    return sendQueryUploadFile(message, file, code);
};

export const addBlackListIp = (login, code, data) => {
    const message = {
        type: 'blackList.addIP',
        lgn: login,
        body: data
    };

    return sendQuery(message, code)
};

export const deleteBlackListIp = (login, code, ip) => {
    const message = {
        type: 'blackList.delIP',
        lgn: login,
        body: {
            ip
        }
    };

    return sendQuery(message, code)
}

export const getUsersList = (login, code) => {
    const message = {
        type: 'users.getList',
        lgn: login
    };

    return sendQuery(message, code);
};

export const getUserProfile = (login, code, id) => {
    const message = {
        type: 'users.getProfile',
        lgn: login,
        body: {
            merchantID: id
        }
    };

    return sendQuery(message, code);
};

export const setUserProfile = (login, code, id, data) => {
    const message = {
        type: 'users.setProfile',
        lgn: login,
        body: {
            merchantID: id,
            profile: data
        }
    };

    return sendQuery(message, code);
};

export const getBlackListItem = (login, code, ip) => {
    const message = {
        type: 'blackList.searchIP',
        lgn: login,
        body: {
            ip
        }
    };

    return sendQuery(message, code);
};

export const getWhiteListItem = (login, code, ip) => {
    const message = {
        type: 'whiteList.searchIP',
        lgn: login,
        body: {
            ip
        }
    };

    return sendQuery(message, code);
};

export const makeUser = ({ login, code, data }) => {
    const message = {
        type: 'users.make',
        lgn: login,
        body: data
    };

    return sendQuery(message, code);
};

export const deleteUser = ({ login, code, id }) => {
    const message = {
        type: 'users.del',
        lgn: login,
        body: {
            id
        }
    };

    return sendQuery(message, code);
};

export const updateUserName = ({ login, code, data }) => {
    const message = {
        type: 'users.update',
        lgn: login,
        body: data
    };

    return sendQuery(message, code);
}

export const getForbiddenList = ({ login, code, data }) => {
    const message = {
        type: 'forbiddennumbers.list',
        lgn: login,
        body: data
    };

    return sendQuery(message, code);
};

export const getForbiddenTotal = (login, code) => {
    const message = {
        type: 'forbiddennumbers.total',
        lgn: login
    };

    return sendQuery(message, code);
}

export const addForbiddenNumber = ({ login, code, number }) => {
    const message = {
        type: 'forbiddennumbers.add',
        lgn: login,
        body: {
            number
        }
    };

    return sendQuery(message, code);
};

export const delForbiddenNumber = ({ login, code, number }) => {
    const message = {
        type: 'forbiddennumbers.del',
        lgn: login,
        body: {
            number
        }
    };

    return sendQuery(message, code);
};