import {
  deleteUser,
  getUserProfile,
  getUsersList,
  makeUser,
  setUserProfile,
  updateUserName,
} from "../../network/kaf-api";
import {
  ADD_NEW_PROFILE,
  DELETE_PROFILE_KAF,
  FAILURE_DELETE_USER,
  FAILURE_MAKE_USER,
  FAILURE_USER_PROFILE,
  FAILURE_USERS_LIST,
  INIT_PROFILE_VALUE,
  REQUEST_USER_PROFILE,
  REQUEST_USERS_LIST,
  SUCCESS_USER_PROFILE,
  SUCCESS_USERS_LIST,
  UPDATE_PROFILE_KAF,
  UPDATE_PROFILE_VALUE,
  UPDATE_BIN_CHECK,
  UPDATE_BIN_CONFIG,
  UPDATE_ATMM_CHECK,
  UPDATE_ATMM_CONFIG,
  UPDATE_ARAMM_CHECK,
  UPDATE_ARAMM_CONFIG,
  UPDATE_TRAFFIC_SCORE_CHECK,
  UPDATE_TRAFFIC_SCORE_CONFIG,
  UPDATE_BIN_CODE_COUNTRY,
} from "./constants";
import { FAILURE_BLACK_LIST } from "../blackList/constants";
import ndarray from "ndarray";

const unflattenUsers = (arr) => {
  let tree = [],
    mappedArr = {},
    arrElem,
    mappedElem;

  for (let i = 0, len = arr.length; i < len; i++) {
    arrElem = arr[parseInt(i)];
    mappedArr[arrElem.id] = arrElem;
    mappedArr[arrElem.id]["children"] = [];
    mappedArr[arrElem.id]["show"] = false;
  }

  for (let id in mappedArr) {
    if (mappedArr.hasOwnProperty(id)) {
      mappedElem = mappedArr[parseInt(id)];
      if (mappedElem.parentID) {
        mappedArr[mappedElem["parentID"]]["children"].push(mappedElem);
      } else {
        tree.push(mappedElem);
      }
    }
  }
  return tree;
};

export const requestGetUsersList = (login, code) => async (dispatch) => {
  dispatch({ type: REQUEST_USERS_LIST });
  try {
    const response = await getUsersList(login, code);

    if (response.data.code === 0) {
      const usersList = unflattenUsers(response.data.body.list);

      const renderPath = (data, prevItem) => {
        data.forEach((item, index) => {
          let itemPath = { ...item, path: "" };
          if (item.children.length === 0) {
            if (prevItem && prevItem.id === item.parentID) {
              itemPath.path = `${prevItem.path}/${item.merchantID}`;
              ndarray(data).set(index, itemPath);
            } else {
              itemPath.path = `/users/${item.merchantID}`;
              ndarray(data).set(index, itemPath);
            }
          } else {
            if (prevItem && prevItem.id === item.parentID) {
              itemPath.path = `${prevItem.parentID}/${item.merchantID}`;
              ndarray(data).set(index, itemPath);
            } else {
              itemPath.path = `/users/${item.id}`;
              ndarray(data).set(index, itemPath);
            }
            renderPath(item.children, itemPath);
          }
        });
      };

      renderPath(usersList);

      dispatch({ type: SUCCESS_USERS_LIST, payload: usersList });
    } else {
      dispatch({
        type: FAILURE_USERS_LIST,
        payload: { message: response.data.msg },
      });
    }
  } catch (e) {
    dispatch({
      type: FAILURE_BLACK_LIST,
      payload: { message: "Не удалось загрузить список пользователей" },
    });
  }
};

export const requestUserProfile = (login, code, id) => async (dispatch) => {
  dispatch({ type: REQUEST_USER_PROFILE });
  try {
    const response = await getUserProfile(login, code, id);

    const colletProfiles = (profile) => {
      return {
        ...profile,
        is_user_blacklisted: profile.is_user_blacklisted
          ? profile.is_user_blacklisted
          : false,
      };
    };

    if (response.data.code === 0) {
      dispatch({
        type: SUCCESS_USER_PROFILE,
        payload: {
          ...response.data.body,
          profiles: response.data.body.profiles
            ? response.data.body.profiles.map((profile) =>
                colletProfiles(profile)
              )
            : [],
        },
      });
    } else {
      dispatch({
        type: FAILURE_USER_PROFILE,
        payload: { message: response.data.msg },
      });
    }
  } catch (e) {
    dispatch({
      type: FAILURE_USER_PROFILE,
      payload: { message: "Не удалось загрузить профиль клиента" },
    });
  }
};

export const requestSetProfileData =
  (login, code, id, data) => async (dispatch) => {
    try {
      const response = await setUserProfile(login, code, id, data);

      if (response.data.code === 0) {
        dispatch(requestUserProfile(login, code, id));
      }
    } catch (e) {}
  };

export const requestAddProfileData =
  (login, code, id, data) => async (dispatch) => {
    try {
      const response = await setUserProfile(login, code, id, data);

      if (response.data.code === 0) {
        dispatch(requestUserProfile(login, code, id));
      }

      return response.data;
    } catch (e) {}
  };

export const requestMakeUser =
  ({ login, code, data }) =>
  async (dispatch) => {
    try {
      const response = await makeUser({
        login,
        code,
        data,
      });

      if (response.data.code === 0) {
        dispatch(requestGetUsersList(login, code));
      } else {
        dispatch({
          type: FAILURE_MAKE_USER,
          payload: { message: response.data.msg },
        });
      }

      return response.data;
    } catch (e) {
      dispatch({
        type: FAILURE_MAKE_USER,
        payload: { message: "Не удалось добавить клиента" },
      });
    }
  };

export const requestDelUser =
  ({ login, code, id }) =>
  async (dispatch) => {
    try {
      const response = await deleteUser({ login, code, id });

      if (response.data.code === 0) {
        dispatch(requestGetUsersList(login, code));
      } else {
        dispatch({
          type: FAILURE_DELETE_USER,
          payload: { message: response.data.msg },
        });
      }

      return response.data;
    } catch (e) {
      dispatch({
        type: FAILURE_DELETE_USER,
        payload: { message: "Не удалось удалить клиента" },
      });
    }
  };

export const requestUpdateUser =
  ({ login, code, data }) =>
  async (dispatch) => {
    try {
      const response = await updateUserName({ login, code, data });

      if (response.data.code === 0) {
        dispatch(requestGetUsersList(login, code));
      } else {
        dispatch({
          type: FAILURE_DELETE_USER,
          payload: { message: response.data.msg },
        });
      }

      return response.data;
    } catch (e) {}
  };

export const updateProfileValue = (idx, value, name) => (dispatch) => {
  dispatch({ type: UPDATE_PROFILE_VALUE, idxProf: idx, value, name });
};

export const updateProfileKaf = (value, name) => (dispatch) => {
  dispatch({ type: UPDATE_PROFILE_KAF, value, name });
};

export const initDefaultProfile = () => (dispatch) => {
  dispatch({ type: INIT_PROFILE_VALUE });
};

export const updateProfileData = (data) => (dispatch) => {
  dispatch({ type: ADD_NEW_PROFILE, data });
};

export const deleteProfileKaf = (idx) => (dispatch) => {
  dispatch({ type: DELETE_PROFILE_KAF, idxProf: idx });
};

export const updateBinCheckValue = (value) => (dispatch) => {
  dispatch({ type: UPDATE_BIN_CHECK, value });
};

export const updateBinConfigValue = (value, name) => (dispatch) => {
  dispatch({ type: UPDATE_BIN_CONFIG, value, name });
};

export const updateBinCodeCountryValue = (value) => (dispatch) => {
  dispatch({ type: UPDATE_BIN_CODE_COUNTRY, value });
};

export const updateAtmmValue = (value) => (dispatch) => {
  dispatch({ type: UPDATE_ATMM_CHECK, value });
};

export const updateAtmmConfigValue = (value, name) => (dispatch) => {
  dispatch({ type: UPDATE_ATMM_CONFIG, value, name });
};

export const updateArammValue = (value) => (dispatch) => {
  dispatch({ type: UPDATE_ARAMM_CHECK, value });
};

export const updateArammConfigValue = (value, name) => (dispatch) => {
  dispatch({ type: UPDATE_ARAMM_CONFIG, value, name });
};

export const updateTrafficScoreValue = (value) => (dispatch) => {
  dispatch({ type: UPDATE_TRAFFIC_SCORE_CHECK, value });
};

export const updateTrafficScoreConfigValue = (value) => (dispatch) => {
  dispatch({ type: UPDATE_TRAFFIC_SCORE_CONFIG, value });
};
