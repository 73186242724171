import React, { useEffect, useRef, useState } from "react";
import MaskInput from "../components/MaskInput";
import Button from "../components/Button/Button";
import { ErrorMessage } from "../components/styles/ErrorMessag";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  ControlStyles,
  TableContainerStyles,
  TableStyle,
} from "../components/styles/Table";
import {
  clearBlackListItem,
  requestAddBlackListIp,
  requestBlackList,
  requestBlackListItem,
  requestDelBlackListIp,
  requestImportBlackList,
} from "../redux/blackList/actions";
import Loader from "../components/Loader/Loader";
import { useCookies } from "react-cookie";
import "../components/styles/pagination.css";
import { useForm, Controller } from "react-hook-form";
import styled from "styled-components";
import statusError from "../media/images/status-error.svg";
import statusSuccess from "../media/images/status-succes.svg";
import TrashIcon from "../media/images/trash-icon";
import Modal from "../components/Modal/Modal";
import useModal from "../hooks/useModal";
import AddIp from "../components/AddIp";
import DellIp from "../components/DelIp";
import ItemIp from "../components/ItemIp";
import * as XLSX from "xlsx";
import FileInput from "../components/FileInput";
import { requestLoader } from "../utils/selector";
import { IMPORT_BLACK_LIST } from "../redux/blackList/constants";
import { isValidIP } from "../utils/helpers";

export const ListControlContainerStyles = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
`;

export const ListHeaderStyles = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const BlackListPage = () => {
  const { blackList, blackListItem, error, loader, total } = useSelector(
    (state) => state.blackList
  );
  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid },
  } = useForm({ mode: "onChange" });
  const { isLoader } = useSelector(requestLoader(IMPORT_BLACK_LIST));
  const [file, setFile] = useState(null);
  const fileRef = useRef();
  const [xlsxArr, setXlsxArr] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [modal, setModal, toggleModal] = useModal();
  const [delModal, setDelModal] = useModal();
  const [ipModal, setIpModal] = useModal();
  const [{ login, code }] = useCookies();
  const [itemIp, setItemIp] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearBlackListItem());
    };
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(requestBlackList(login, code, 100, 0));
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!delModal) {
      setTimeout(() => {
        setItemIp(null);
      }, 300);
    }
  }, [delModal]);

  useEffect(() => {
    if (!ipModal) {
      setTimeout(() => {
        setItemIp(null);
      }, 300);
    }
  }, [ipModal]);

  const statusIcon = (status) => {
    let icon;
    if (!status) {
      icon = <img src={statusError} alt="error" />;
    } else {
      icon = <img src={statusSuccess} alt="success" />;
    }

    return icon;
  };

  const clearItem = () => {
    dispatch(clearBlackListItem());
  };

  const addIp = (data) => {
    dispatch(requestAddBlackListIp(login, code, data)).then((res) => {
      if (res.code === 0) {
        setModal(false);
        dispatch(
          requestBlackList(
            login,
            code,
            blackList.length < 100 ? 100 : blackList.length,
            0
          )
        );
      }
    });
  };

  const deleteIp = (ip, single) => {
    if (single) {
      dispatch(requestDelBlackListIp(login, code, ip)).then((res) => {
        if (res.code === 0) {
          clearItem();
          dispatch(
            requestBlackList(
              login,
              code,
              blackList.length < 100 ? 100 : blackList.length,
              0
            )
          );
          setDelModal(false);
        }
      });
    } else {
      dispatch(requestDelBlackListIp(login, code, ip)).then((res) => {
        if (res.code === 0) {
          dispatch(
            requestBlackList(
              login,
              code,
              blackList.length < 100 ? 100 : blackList.length,
              0
            )
          );
          setDelModal(false);
        }
      });
    }
  };

  const updateList = () => {
    dispatch(
      requestBlackList(
        login,
        code,
        100,
        blackList.length === 0 ? 0 : blackList[blackList.length - 1].id,
        blackList
      )
    );
  };

  const searchIP = (data) => {
    dispatch(requestBlackListItem(login, code, data.ip));
  };

  const clearListItem = () => {
    dispatch(clearBlackListItem());
    reset({ ip: "" }, { keepDefaultValues: false });
  };

  const openItem = (item) => {
    setItemIp(item);
    setIpModal(true);
  };

  const openDelIp = (e, item) => {
    setItemIp(item);
    setDelModal(true);
    e.stopPropagation();
  };

  const handleChangeFile = (e) => {
    setErrorMessage(null);
    const files = e.target.files[0];
    if (files) {
      const fileType = files.name.split(".").pop();
      if (fileType === "xlsx" || fileType === "xls") {
        setFile(files);
        readFile(files);
      } else {
        fileRef.current.value = null;
        setErrorMessage("Некорректный формат файла, требуется XLSX или XLS");
      }
    }
  };

  const importFile = () => {
    dispatch(requestImportBlackList(login, file, code)).then((res) => {
      if (res.code === 0) {
        dispatch(requestBlackList(login, code, 100, 0));
        if (fileRef.current) {
          fileRef.current.value = null;
          setFile(null);
          setXlsxArr([]);
        }
      }
    });
  };

  const checkColumnOrder = (obj) => {
    let isValidColumns = false;
    const arrKeyColumn = Object.keys(obj);
    arrKeyColumn.forEach((key, index) => {
      switch (true) {
        case arrKeyColumn[index] === "Ip" && index === 0:
          isValidColumns = true;
          break;
        case arrKeyColumn[index] === "Country" && index === 1:
          isValidColumns = true;
          break;
        case arrKeyColumn[index] === "Tor" && index === 2:
          isValidColumns = true;
          break;
        case arrKeyColumn[index] === "Comment" && index === 3:
          isValidColumns = true;
          break;
        default:
          isValidColumns = false;
          break;
      }
    });
    return isValidColumns;
  };

  const readFile = (oFile) => {
    const reader = new FileReader();

    reader.onload = function (e) {
      const data = e.target.result;
      const workbook = XLSX.read(data, {
        type: "binary",
      });

      workbook.SheetNames.forEach(function (sheetName) {
        const XL_row_object = XLSX.utils.sheet_to_row_object_array(
          workbook.Sheets[sheetName]
        );
        if (checkColumnOrder(XL_row_object[0])) {
          setXlsxArr(XL_row_object);
        } else {
          fileRef.current.value = null;
          setFile(null);
          setXlsxArr([]);
          setErrorMessage(
            "Проверьте поля в файле. Корректный порядок полей (Ip, Country, Tor, Comment)"
          );
        }
      });
    };
    if (oFile) {
      reader.readAsBinaryString(oFile);
    }
  };

  const clearFile = () => {
    if (fileRef.current) {
      fileRef.current.value = null;
      setFile(null);
      setXlsxArr([]);
    }
  };

  const renderBlackList = () => {
    return blackList.length > 0 ? (
      <>
        <ListHeaderStyles>
          <h3>Список запрещенных IP адресов</h3>{" "}
          {total && <span>Количество: {total}</span>}
        </ListHeaderStyles>
        <TableStyle>
          <div className="table-header-item">№</div>
          <div className="table-header-item">IP</div>
          <div className="table-header-item">Дата</div>
          <div className="table-header-item">Комментарий</div>
          <div className="table-header-item table-item--center">Код страны</div>
          <div className="table-header-item table-item--center">TOR</div>
          <div className="table-header-item" />
        </TableStyle>
        {blackList.map((item, index) => (
          <TableStyle
            key={item.id || `${index + 2}${item.ip}.${item.country}`}
            onClick={() => openItem(item)}
          >
            <div className="table-item">{index + 1}</div>
            <div className="table-item table-item--bold">{item.ip}</div>
            <div className="table-item">
              {moment(item.date, "YYYYMMDDhhmmss").format("DD.MM.YYYY hh:mm")}
            </div>
            <div className="table-item">{item.comment}</div>
            <div className="table-item table-item--bold table-item--center">
              {item.country}
            </div>
            <div className="table-item table-item--center">
              {statusIcon(item.tor)}
            </div>
            <div className="table-item table-item--delete" title="Удалить">
              <button onClick={(e) => openDelIp(e, item)}>
                <TrashIcon size={16} color={"#000"} />
              </button>
            </div>
          </TableStyle>
        ))}
        {total > 100 && (
          <Button updateList onClick={updateList}>
            Загрузить ещё
          </Button>
        )}
      </>
    ) : (
      !loader && <h3>Список IP адресов пуст</h3>
    );
  };

  return (
    <div>
      {isLoader && <Loader type="overlay" />}
      {error && <ErrorMessage>{error.message}</ErrorMessage>}
      <Modal
        isShowing={modal}
        hide={() => setModal(false)}
        title="Добавить IP-адрес"
      >
        <AddIp
          onClick={addIp}
          hide={() => setModal(false)}
          tor={false}
          country={""}
        />
      </Modal>
      <Modal
        isShowing={delModal}
        hide={() => setDelModal(false)}
        title="Удалить IP-адрес"
      >
        <DellIp
          itemIp={itemIp}
          hide={() => setDelModal(false)}
          onClick={() => deleteIp(itemIp.ip, !!blackListItem)}
        />
      </Modal>
      <Modal
        isShowing={ipModal}
        hide={() => setIpModal(false)}
        title={`IP ${itemIp && itemIp.ip}`}
      >
        <ItemIp itemIp={itemIp} />
      </Modal>
      {!(xlsxArr.length > 0 && file) && (
        <ListControlContainerStyles>
          <Button onClick={toggleModal}>Добавить ip-адрес</Button>
          <form onSubmit={handleSubmit(searchIP)}>
            <ControlStyles>
              <Controller
                name="ip"
                control={control}
                rules={{
                  required: true,
                  validate: (value) => isValidIP(value),
                }}
                defaultValue=""
                render={({ field }) => (
                  <MaskInput
                    type="ip"
                    placeholder="Введите IP Адрес"
                    guide={false}
                    {...field}
                  />
                )}
              />
              <Button disabled={!isValid}>Поиск</Button>
            </ControlStyles>
          </form>
        </ListControlContainerStyles>
      )}
      <ListControlContainerStyles>
        <FileInput
          fileSelect={handleChangeFile}
          file={file}
          ref={fileRef}
          clear={clearFile}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />
      </ListControlContainerStyles>
      {file && xlsxArr.length > 0 ? (
        <Button onClick={importFile}>Загрузить список</Button>
      ) : null}
      {file && xlsxArr.length > 0 ? (
        <TableContainerStyles>
          <>
            <ListHeaderStyles>
              <h3>Список запрещенных IP адресов из файла</h3>
            </ListHeaderStyles>
            <TableStyle xlsxArray>
              <div className="table-header-item">№</div>
              <div className="table-header-item">IP</div>
              <div className="table-header-item">Комментарий</div>
              <div className="table-header-item table-item--center">
                Код страны
              </div>
              <div className="table-header-item table-item--center">TOR</div>
            </TableStyle>
            {xlsxArr.map((item, index) => (
              <TableStyle key={`${item.ip}${index + 1}`} xlsxArray>
                <div className="table-item">{index + 1}</div>
                <div className="table-item table-item--bold">{item.Ip}</div>
                <div className="table-item">{item.Comment}</div>
                <div className="table-item table-item--bold table-item--center">
                  {item.Country}
                </div>
                <div className="table-item table-item--center">
                  {statusIcon(JSON.parse(item.Tor))}
                </div>
              </TableStyle>
            ))}
          </>
        </TableContainerStyles>
      ) : (
        <TableContainerStyles>
          {loader && <Loader />}
          {blackListItem ? (
            <>
              <ListHeaderStyles>
                <h3>Список запрещенных IP адресов</h3>{" "}
                <Button onClick={clearListItem}>Сбросить</Button>
              </ListHeaderStyles>
              <TableStyle country={true}>
                <div className="table-header-item">№</div>
                <div className="table-header-item">IP</div>
                <div className="table-header-item">Дата</div>
                <div className="table-header-item">Комментарий</div>
                <div className="table-header-item">Код страны</div>
                <div className="table-header-item table-item--center">TOR</div>
                <div className="table-header-item" />
              </TableStyle>
              {blackListItem.map((item, index) => (
                <TableStyle
                  country={true}
                  key={item.id || `${index + 1}${item.ip}.${item.country}`}
                  onClick={() => openItem(item)}
                >
                  <div className="table-item">{index + 1}</div>
                  <div className="table-item table-item--bold">{item.ip}</div>
                  <div className="table-item">
                    {moment(item.date, "YYYYMMDDhhmmss").format(
                      "DD.MM.YYYY hh:mm"
                    )}
                  </div>
                  <div className="table-item">{item.comment}</div>
                  <div className="table-item table-item--bold">
                    {item.country}
                  </div>
                  <div className="table-item table-item--center">
                    {statusIcon(item.tor)}
                  </div>
                  <div
                    className="table-item table-item--delete"
                    title="Удалить"
                  >
                    <button onClick={(e) => openDelIp(e, item)}>
                      <TrashIcon size={16} color={"#000"} />
                    </button>
                  </div>
                </TableStyle>
              ))}
            </>
          ) : (
            renderBlackList()
          )}
        </TableContainerStyles>
      )}
    </div>
  );
};

export default BlackListPage;
