import React from "react";
import { FraudCheckboxStyles } from "../styles/Inputs";

const FraudCheckbox = ({ checked, onChange, name, value, title, disabled, info }) => {
  return (
    <FraudCheckboxStyles disabled={disabled} title={info}>
      <input
        checked={checked}
        type="checkbox"
        name={name}
        title={title}
        disabled={disabled}
        value={value}
        onChange={onChange}
      />
      <div className="checkmark-container">
        <p>{title}</p> <div className="checkmark" />
      </div>
    </FraudCheckboxStyles>
  );
};

export default FraudCheckbox;
