import {toast} from "react-toastify";
import {
    addBlackListIp,
    deleteBlackListIp,
    getBlackList,
    getBlackListItem,
    getBlackListTotal, importBlackList
} from "../../network/kaf-api";
import {
    FAILURE_BLACK_LIST, FAILURE_BLACK_LIST_ITEM, GET_BLACK_LIST_TOTAL, IMPORT_BLACK_LIST,
    REQUEST_BLACK_LIST,
    REQUEST_BLACK_LIST_ITEM,
    SUCCESS_BLACK_LIST,
    SUCCESS_BLACK_LIST_ITEM
} from "./constants";
import {startAction, stopAction} from "../ui/action";

export const requestBlackList = (login, code, count, lastId, array) => async dispatch => {
    dispatch({type: REQUEST_BLACK_LIST});
    try {
        const response = await getBlackList(login, code, count, lastId);

        if (response.data.code === 0) {
            if(array) {
                const newArray = array.concat(response.data.body.ips);
                dispatch({
                    type: SUCCESS_BLACK_LIST, payload: response.data.body ? {
                        ips: newArray
                    } : { ips: [] }
                });
            }else {
                dispatch({
                    type: SUCCESS_BLACK_LIST, payload: response.data.body ? {
                        ips: response.data.body.ips
                    } : { ips: [] }
                });
                dispatch(requestBlackListTotal(login, code));
            }
        } else {
            dispatch({type: FAILURE_BLACK_LIST, payload: {message: response.data.msg}});
        }
        return response.data;
    } catch (e) {
        dispatch({type: FAILURE_BLACK_LIST, payload: {message: 'Ошибка при загрузке IP адресов'}})
    }
};

export const requestBlackListTotal = (login, code) => async dispatch => {
    try {
        const response = await getBlackListTotal(login, code);

        dispatch({ type: GET_BLACK_LIST_TOTAL, payload: response.data.body.total })
    }catch (e) {

    }
};

export const requestAddBlackListIp = (login, code, data) => async () => {
    try {
        const response = await addBlackListIp(login, code, data);

        return response.data;
    } catch (e) {

    }
};

export const requestDelBlackListIp = (login, code, ip) => async () => {
    try {
        const response = await deleteBlackListIp(login, code, ip);

        return response.data;
    } catch (e) {

    }
};

export const requestImportBlackList = (login, file, code) => async dispatch => {
    dispatch(startAction(IMPORT_BLACK_LIST));
    try {
        const response = await importBlackList(login, file, code);
        toast.success('Список успешно импортирован');
        dispatch(stopAction(IMPORT_BLACK_LIST));
        return response.data;
    } catch (e) {

    }
};

export const requestBlackListItem = (login, code, ip) => async dispatch => {
    dispatch({ type: REQUEST_BLACK_LIST_ITEM });
    try {
        const response = await getBlackListItem(login, code, ip);

        if(response.data.code === 0) {
            if(response.data.body) {
                dispatch({ type: SUCCESS_BLACK_LIST_ITEM, payload: response.data.body.ips });
            }else {
                dispatch({ type: FAILURE_BLACK_LIST_ITEM, payload: {message: 'Не удалось найти IP адрес'}})
            }
        }else {
            dispatch({type: FAILURE_BLACK_LIST_ITEM, payload: {message: response.data.msg}});
        }

    } catch (e) {

    }
};

export const clearBlackListItem = () => dispatch => {
    dispatch({ type: SUCCESS_BLACK_LIST_ITEM, payload: null })
}

