import {
    REFRESH_ACTION_START,
    REFRESH_ACTION_STOP, REMOVE_BLOCKED,
    SET_ACCESS_DENIED,
    SET_REQUEST_RESULT,
    START_ACTION,
    STOP_ACTION
} from "./constants";

export const startAction = (name, params) => ({
        type: START_ACTION,
        payload: {
            action:
                {name, params}
        }
});

export const stopAction = name => ({
    type: STOP_ACTION,
    payload: { name }
});

export const refreshActionStart = refreshAction => ({
    type: REFRESH_ACTION_START,
    payload: { refreshAction }
});

export const refreshActionStop = refreshAction => ({
    type: REFRESH_ACTION_STOP,
    payload: { refreshAction }
});

export const setRequestResult = result => ({
    type: SET_REQUEST_RESULT,
    payload: result
});

export const setAccessDenied = (name, params) => ({
    type: SET_ACCESS_DENIED,
    payload: {
        action:
            {name, params}
    }
});

export const removeDenied = (name) => ({
    type: REMOVE_BLOCKED,
    payload: {name}
});