import React from 'react';
import SignIn from "../components/SignIn";
import styled from 'styled-components';

const AuthLayoutStyles = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f8f8f8;
`;

const AuthLayout = () => {
    return (
        <AuthLayoutStyles>
            <SignIn />
        </AuthLayoutStyles>
    )
};

export default AuthLayout;