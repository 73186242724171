import React, { useState } from "react";
import { deleteProfileKaf, updateProfileValue } from "../../redux/users/action";
import { useDispatch } from "react-redux";
import FraudCheckbox from "../FraudCheckbox";
import RangeInput from "../RangeInput";
import styled from "styled-components";
import ArrowNav from "../../media/images/arrow-nav";
import Select from "../Select";
import TrashIcon from "../../media/images/trash-icon";

export const FraudProfileContainerStyles = styled.div`
  margin-bottom: 32px;
`;

export const FraudProfileHeaderStyles = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  padding: 16px 32px;
  box-sizing: border-box;
  background-color: #fff;

  & h3 {
    margin: 0;
    display: flex;
    align-items: center;
  }

  & span {
    font-size: 14px;
    color: red;
    font-weight: normal;
    cursor: pointer;
    margin-left: 8px;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const FraudProfileOptionsStyles = styled.div`
  padding: 6px 16px 16px 16px;

  & select {
    margin-bottom: 6px;
    margin-top: 6px;
  }

  & label {
    margin-bottom: 12px;
  }

  & > div {
    margin-bottom: 12px;
  }
`;

const FraudProfileItem = ({ item, index }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  const updateFS = (index, e) => {
    const name = e.target.name;
    const value = Number(e.target.value);
    dispatch(updateProfileValue(index, value, name));
  };

  const updateFraudSelect = (index, e) => {
    const name = e.target.name;
    const value = e.target.value;
    dispatch(updateProfileValue(index, value, name));
  };

  const updateFraudCheckbox = (index, e) => {
    const name = e.target.name;
    const value = e.target.checked;
    dispatch(updateProfileValue(index, value, name));
  };

  const openProfile = () => {
    setIsOpen(!isOpen);
  };

  const deleteProfKaf = (e) => {
    dispatch(deleteProfileKaf(index));
    e.stopPropagation();
  };

  return (
    <FraudProfileContainerStyles>
      <FraudProfileHeaderStyles onClick={openProfile}>
        <h3>
          Регион {item.country_code}{" "}
          {item.country_code !== "ALL" && (
            <span onClick={deleteProfKaf}>
              <TrashIcon size={18} color="#000" />
            </span>
          )}
        </h3>
        <ArrowNav />
      </FraudProfileHeaderStyles>
      {isOpen && (
        <FraudProfileOptionsStyles>
          <RangeInput
            value={item.fraud_score}
            onChange={(e) => updateFS(index, e)}
            name="fraud_score"
            title={`fraud_score - ${item.fraud_score}`}
            max={101}
            min={0}
          />
          <Select
            value={item.abuse_velocity}
            onChange={(e) => updateFraudSelect(index, e)}
            name="abuse_velocity"
            label="abuse_velocity"
          >
            <option value="low">Low</option>
            <option value="middle">Middle</option>
            <option value="high">High</option>
          </Select>
          <FraudCheckbox
            checked={item.bot_status}
            title="bot_status"
            item={item.bot_status}
            name="bot_status"
            value={item.bot_status}
            onChange={(e) => updateFraudCheckbox(index, e)}
          />
          <FraudCheckbox
            checked={item.proxy}
            title="proxy"
            name="proxy"
            value={item.proxy}
            item={item.proxy}
            onChange={(e) => updateFraudCheckbox(index, e)}
          />
          <FraudCheckbox
            checked={item.vpn}
            title="vpn"
            name="vpn"
            value={item.vpn}
            item={item.vpn}
            onChange={(e) => updateFraudCheckbox(index, e)}
          />
          <FraudCheckbox
            checked={item.activeVpn}
            title="activeVpn"
            name="activeVpn"
            value={item.activeVpn}
            item={item.activeVpn}
            onChange={(e) => updateFraudCheckbox(index, e)}
          />
          <FraudCheckbox
            checked={item.tor}
            title="tor"
            name="tor"
            value={item.tor}
            item={item.tor}
            onChange={(e) => updateFraudCheckbox(index, e)}
          />
          <FraudCheckbox
            checked={item.active_tor}
            title="active_tor"
            name="active_tor"
            value={item.active_tor}
            item={item.active_tor}
            onChange={(e) => updateFraudCheckbox(index, e)}
          />
          <FraudCheckbox
            checked={item.recent_abuse}
            title="recent_abuse"
            name="recent_abuse"
            item={item.recent_abuse}
            value={item.recent_abuse}
            onChange={(e) => updateFraudCheckbox(index, e)}
          />
          <FraudCheckbox
            checked={item.is_user_blacklisted}
            title="is_user_blacklisted"
            item={item.is_user_blacklisted}
            name="is_user_blacklisted"
            value={item.is_user_blacklisted}
            onChange={(e) => updateFraudCheckbox(index, e)}
          />
          <FraudCheckbox
            checked={item.is_user_whitelisted}
            title="is_user_whitelisted"
            item={item.is_user_whitelisted}
            name="is_user_whitelisted"
            value={item.is_user_whitelisted}
            onChange={(e) => updateFraudCheckbox(index, e)}
          />
        </FraudProfileOptionsStyles>
      )}
    </FraudProfileContainerStyles>
  );
};

export default FraudProfileItem;
