import React from "react";

const ArrowNav = () => {
    return (
        <svg className="arrow-nav" width="14" height="9" viewBox="0 0 14 9" fill="none">
            <path d="M1 1L7 7L13 1" stroke="#8F8D8E" strokeWidth="1.5"/>
        </svg>
    )
};

export default ArrowNav;