import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import {
  updateAtmmConfigValue,
  updateAtmmValue,
} from "../../redux/users/action";
import FraudCheckbox from "../FraudCheckbox";
import RangeInput from "../RangeInput";

const AtmmConfigStyles = styled.div`
  padding: 10px 16px 16px 16px;
  & label {
    margin-bottom: 12px;
  }
`;

const AtmmConfig = ({ userProfile }) => {
  const range = { max: 100, min: 0 };
  const dispatch = useDispatch();

  const updateAtmmCheckbox = (e) => {
    const value = e.target.checked;
    dispatch(updateAtmmValue(value));
  };

  const updateAtmmConfigRange = (e) => {
    const name = e.target.name;
    const value = Number(e.target.value);
    dispatch(updateAtmmConfigValue(value, name));
  };

  return (
    <div>
      <FraudCheckbox
        checked={userProfile.atmm.status || false}
        title="atmm"
        name="atmm"
        value={userProfile.atmm.status || false}
        onChange={(e) => updateAtmmCheckbox(e)}
      />
      {userProfile.atmm.status && (
        <AtmmConfigStyles>
          <RangeInput
            value={userProfile.atmm.topics.antifraud_ip_mascedpan_output}
            onChange={(e) => updateAtmmConfigRange(e)}
            name="antifraud_ip_mascedpan_output"
            title={`antifraud_ip_mascedpan_output - ${userProfile.atmm.topics.antifraud_ip_mascedpan_output}`}
            max={range.max}
            min={range.min}
          />
          <RangeInput
            value={userProfile.atmm.topics.antifraud_ip_output}
            onChange={(e) => updateAtmmConfigRange(e)}
            name="antifraud_ip_output"
            title={`antifraud_ip_output - ${userProfile.atmm.topics.antifraud_ip_output}`}
            max={range.max}
            min={range.min}
          />
          <RangeInput
            value={userProfile.atmm.topics.antifraud_mascedpan_output}
            onChange={(e) => updateAtmmConfigRange(e)}
            name="antifraud_mascedpan_output"
            title={`antifraud_mascedpan_output - ${userProfile.atmm.topics.antifraud_mascedpan_output}`}
            max={range.max}
            min={range.min}
          />
        </AtmmConfigStyles>
      )}
    </div>
  );
};

export default AtmmConfig;
