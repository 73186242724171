import React, {Fragment, useEffect} from "react";
import { useIdleTimer } from 'react-idle-timer'
import {useDispatch} from "react-redux";
import {signOut} from "../../redux/auth/action";
import {useCookies} from "react-cookie";

const TimeSession = () => {
    const [{login, code}] = useCookies();
    const dispatch = useDispatch();
    const timeout = 900000;

    const handleOnIdle = () => {
        if(!!(login && code)) {
            dispatch(signOut());
        }
    };

    useEffect(() => {
        if(localStorage.getItem('session')) {
            if((new Date().getTime() - Number(localStorage.getItem('session'))) > timeout) {
                dispatch(signOut());
            }
        }else {
            dispatch(signOut());
        }
        //eslint-disable-next-line
    }, []);

    const handleOnAction = () => {
        localStorage.setItem('session', new Date().getTime().toString());
    };

    useIdleTimer({
        timeout,
        onIdle: handleOnIdle,
        onAction: handleOnAction,
        debounce: 500
    })

    return <Fragment />;

};

export default TimeSession;