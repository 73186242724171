import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useCookies} from "react-cookie";
import {
    requestAddForbiddenNumber,
    requestDelForbiddenNumber,
    requestForbiddenNumbersList
} from "../redux/forbiddenNumbers/action";
import AddForbiddenNumbers from "../containers/AddForbiddenNumbers";
import {ListControlContainerStyles, ListHeaderStyles} from "./BlackListPage";
import {TableContainerStyles, TableStyle} from "../components/styles/Table";
import Loader from "../components/Loader/Loader";
import moment from "moment";
import {ErrorMessage} from "../components/styles/ErrorMessag";
import Button from "../components/Button/Button";
import TrashIcon from "../media/images/trash-icon";

const ForbiddenNumbers = () => {
    const {error, forbiddenNumberList, loader, total} = useSelector(state => state.forbiddenList);
    const [{login, code}] = useCookies();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(requestForbiddenNumbersList({ login, code, data: { count: 100, lastId: 0 }}));
        //eslint-disable-next-line
    }, []);

    const deleteNumber = (number) => {
        dispatch(requestDelForbiddenNumber({ login, code, number}))
            .then(res => res.code === 0 && dispatch(requestForbiddenNumbersList({ login, code, data: { count: forbiddenNumberList.length < 100 ? 100 : forbiddenNumberList.length, lastId: 0 }})))
    }

    const addNumber = (number) => {
        return dispatch(requestAddForbiddenNumber({login, code, number}))
            .then(res => res.code === 0 && dispatch(requestForbiddenNumbersList({ login, code, data: { count: forbiddenNumberList.length < 100 ? 100 : forbiddenNumberList.length, lastId: 0 }})));
    }

    const updateList = () => {
        dispatch(requestForbiddenNumbersList({ login, code, data: { count: 100, lastId: forbiddenNumberList.length === 0 ? 0 : forbiddenNumberList[forbiddenNumberList.length - 1].id }, array: forbiddenNumberList}));
    }

    return (
        <div>
            {error && <ErrorMessage>{error.message}</ErrorMessage>}
            <ListControlContainerStyles>
                <AddForbiddenNumbers addNumber={addNumber}/>
            </ListControlContainerStyles>
            <TableContainerStyles>
                {loader && <Loader/>}
                {forbiddenNumberList.length > 0 ?
                    <>
                        <ListHeaderStyles><h3>Список запрещенных номеров</h3> {total && <span>Количество: {total}</span>}</ListHeaderStyles>
                        <TableStyle forbiddenList>
                            <div className='table-header-item'>№</div>
                            <div className='table-header-item'>Номер</div>
                            <div className='table-header-item'>Дата</div>
                            <div className='table-header-item'/>
                        </TableStyle>
                        {forbiddenNumberList.map((item, index) => (
                            <TableStyle key={index} forbiddenList>
                                <div className='table-item'>{index + 1}</div>
                                <div className='table-item table-item--bold'>{item.number}</div>
                                <div
                                    className='table-item'>{moment(item.date, 'YYYYMMDDhhmmss').format('DD.MM.YYYY hh:mm')}</div>
                                <div className='table-item table-item--delete' title='Удалить'>
                                    <button onClick={() => deleteNumber(item.number)}><TrashIcon size={16} color={'#000'}/></button>
                                </div>
                            </TableStyle>
                        ))}
                        {total > 100 && <Button updateList onClick={updateList}>Загрузить ещё</Button>}
                    </>
                    :
                    !loader && <h3>Список номеров пуст</h3>}
            </TableContainerStyles>
        </div>
    )
};

export default ForbiddenNumbers;