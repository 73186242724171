import React from "react";
import {InputContainer} from "../styles/InputContainer";

const TextArea = React.forwardRef(({ label, id, errorMessage, ...attrs }, ref) => {
	return (
		<InputContainer>
			{label && <label htmlFor={id}>{label}</label>}
			<textarea {...attrs} ref={ref} id={id} className={errorMessage && 'input-error'} />
			{errorMessage && <span role="alert">{errorMessage}</span>}
		</InputContainer>
	)
});

export default TextArea;