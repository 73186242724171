import {addForbiddenNumber, delForbiddenNumber, getForbiddenList, getForbiddenTotal} from "../../network/kaf-api";
import {
    FAILURE_ADD_FORBIDDEN_NUMBER,
    FAILURE_DEL_FORBIDDEN_NUMBER,
    FAILURE_FORBIDDEN_NUMBERS_LIST,
    GET_FORBIDDEN_NUMBERS_TOTAL,
    REQUEST_ADD_FORBIDDEN_NUMBER,
    REQUEST_DEL_FORBIDDEN_NUMBER,
    REQUEST_FORBIDDEN_NUMBERS_LIST,
    SUCCESS_ADD_FORBIDDEN_NUMBER,
    SUCCESS_DEL_FORBIDDEN_NUMBER,
    SUCCESS_FORBIDDEN_NUMBERS_LIST
} from "./constants";

export const requestForbiddenNumbersList = ({ login, code, data, array }) => async dispatch => {
    dispatch({ type: REQUEST_FORBIDDEN_NUMBERS_LIST });
    try {
        const response = await getForbiddenList({
            login,
            code,
            data
        });

        if(response.data.code === 0) {
            if(array) {
                const newArray = array.concat(response.data.body.numbers);
                dispatch({
                    type: SUCCESS_FORBIDDEN_NUMBERS_LIST, payload: response.data.body ? {
                        numbers: newArray
                    } : { numbers: []}
                });
            }else {
                dispatch({
                    type: SUCCESS_FORBIDDEN_NUMBERS_LIST, payload: response.data.body ? {
                        numbers: response.data.body.numbers
                    } : { numbers: [] }
                });
                dispatch(requestForbiddenNumbersTotal(login, code));
            }

        }else {
            dispatch({type: FAILURE_FORBIDDEN_NUMBERS_LIST, payload: {message: response.data.msg}});
        }

    }catch (e) {
        dispatch({type: FAILURE_FORBIDDEN_NUMBERS_LIST, payload: {message: 'Ошибка при загрузке запрещенных номеров'}});
    }
}

export const requestForbiddenNumbersTotal = (login, code) => async dispatch => {
    try {
        const response = await getForbiddenTotal(login, code);

        dispatch({ type: GET_FORBIDDEN_NUMBERS_TOTAL, payload: response.data.body.total });
    }catch (e) {

    }
}

export const requestAddForbiddenNumber = ({ login, code, number }) => async dispatch => {
    dispatch({ type: REQUEST_ADD_FORBIDDEN_NUMBER});
    try {
        const response = await addForbiddenNumber({
            login,
            code,
            number
        });

        if(response.data.code === 0) {
            dispatch({ type: SUCCESS_ADD_FORBIDDEN_NUMBER });
        }else {
            dispatch({type: FAILURE_ADD_FORBIDDEN_NUMBER, payload: {message: response.data.msg}});
        }

        return response.data;
    }catch (e) {
        dispatch({type: FAILURE_ADD_FORBIDDEN_NUMBER, payload: {message: 'Ошибка при добавлении номера'}});
    }
};

export const requestDelForbiddenNumber = ({ login, code, number }) => async dispatch => {
    dispatch({ type: REQUEST_DEL_FORBIDDEN_NUMBER});
    try {
        const response = await delForbiddenNumber({
            login,
            code,
            number
        });

        if(response.data.code === 0) {
            dispatch({ type: SUCCESS_DEL_FORBIDDEN_NUMBER });
        }else {
            dispatch({type: FAILURE_DEL_FORBIDDEN_NUMBER, payload: {message: response.data.msg}});
        }

        return response.data;
    }catch (e) {
        dispatch({type: FAILURE_DEL_FORBIDDEN_NUMBER, payload: {message: 'Ошибка при добавлении номера'}});
    }
};