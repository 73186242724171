import React, { useState } from "react";
import RangeInput from "../components/RangeInput";
import Select from "../components/Select";
import FraudCheckbox from "../components/FraudCheckbox";
import ISOCountry from "../media/files/iso-3166.json";
import { FraudProfileOptionsStyles } from "../components/FraudProfileItem/FraudProfileItem";
import { ButtonStyle } from "../components/styles/Button";
import { useDispatch, useSelector } from "react-redux";
import { requestAddProfileData } from "../redux/users/action";
import { useCookies } from "react-cookie";
import { useEffect } from "react";

const CreateProfileForm = ({ user, hide }) => {
  const { userProfile } = useSelector((state) => state.users);
  const [{ login, code }] = useCookies();
  const [control, setControl] = useState({
    fraud_score: 0,
    country_code: "ALL",
    abuse_velocity: "low",
    bot_status: false,
    proxy: false,
    vpn: false,
    activeVpn: false,
    recent_abuse: false,
    active_tor: false,
    tor: false,
    is_user_blacklisted: false,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      userProfile.profiles.some((profile) => profile.country_code === "ALL")
    ) {
      setControl({
        ...control,
        country_code: clearISOCountry(ISOCountry)[0]["alpha-2"],
      });
    }
    //eslint-disable-next-line
  }, []);

  const updateFS = (e) => {
    const name = e.target.name;
    const value = Number(e.target.value);
    setControl({ ...control, [name]: value });
  };

  const updateFraudSelect = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setControl({ ...control, [name]: value });
  };

  const updateFraudCheckbox = (e) => {
    const name = e.target.name;
    const value = e.target.checked;
    setControl({ ...control, [name]: value });
  };

  const addProfile = () => {
    const profile = userProfile;
    profile.profiles.push(control);
    dispatch(requestAddProfileData(login, code, user.merchantID, profile)).then(
      (res) => res.code === 0 && hide()
    );
  };

  const clearISOCountry = (arr) => {
    if (userProfile) {
      return arr.filter((item) => {
        const countryItem = userProfile.profiles.find(
          (profile) => profile.country_code === item["alpha-2"]
        );
        return !countryItem;
      });
    }
  };

  return (
    <FraudProfileOptionsStyles>
      <RangeInput
        value={control.fraud_score}
        onChange={(e) => updateFS(e)}
        name="fraud_score"
        title={`fraud_score - ${control.fraud_score}`}
        max={100}
        min={0}
      />
      <Select
        value={control.country_code}
        onChange={(e) => updateFraudSelect(e)}
        name="country_code"
        label="country_code"
      >
        {clearISOCountry(ISOCountry).map((country) => (
          <option key={country["alpha-2"]} value={country["alpha-2"]}>
            {country["alpha-2"]} - {country.name}
          </option>
        ))}
      </Select>
      <Select
        value={control.abuse_velocity}
        onChange={(e) => updateFraudSelect(e)}
        name="abuse_velocity"
        label="abuse_velocity"
      >
        <option value="low">Low</option>
        <option value="middle">Middle</option>
        <option value="high">High</option>
      </Select>
      <FraudCheckbox
        checked={control.bot_status}
        title="bot_status"
        name="bot_status"
        value={control.bot_status}
        onChange={(e) => updateFraudCheckbox(e)}
      />
      <FraudCheckbox
        checked={control.proxy}
        title="proxy"
        name="proxy"
        value={control.proxy}
        onChange={(e) => updateFraudCheckbox(e)}
      />
      <FraudCheckbox
        checked={control.vpn}
        title="vpn"
        name="vpn"
        value={control.vpn}
        onChange={(e) => updateFraudCheckbox(e)}
      />
      <FraudCheckbox
        checked={control.activeVpn}
        title="activeVpn"
        name="activeVpn"
        value={control.activeVpn}
        onChange={(e) => updateFraudCheckbox(e)}
      />
      <FraudCheckbox
        checked={control.active_tor}
        title="active_tor"
        name="active_tor"
        value={control.active_tor}
        onChange={(e) => updateFraudCheckbox(e)}
      />
      <FraudCheckbox
        checked={control.tor}
        title="tor"
        name="tor"
        value={control.tor}
        onChange={(e) => updateFraudCheckbox(e)}
      />
      <FraudCheckbox
        checked={control.recent_abuse}
        title="recent_abuse"
        name="recent_abuse"
        value={control.recent_abuse}
        onChange={(e) => updateFraudCheckbox(e)}
      />
      <FraudCheckbox
        checked={control.is_user_blacklisted}
        title="is_user_blacklisted"
        item={control.is_user_blacklisted}
        name="is_user_blacklisted"
        value={control.is_user_blacklisted}
        onChange={(e) => updateFraudCheckbox(e)}
      />
      <ButtonStyle formStyle onClick={addProfile}>
        Создать
      </ButtonStyle>
    </FraudProfileOptionsStyles>
  );
};

export default CreateProfileForm;
