import styled from 'styled-components';

export const ModalOverlayStyles = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.5);
    overflow-y: auto;
    
    &.modal-enter {
      opacity: 0;
    }

    &.modal-enter-active {
        opacity: 1;
        transition: opacity 300ms;
    }

    &.modal-exit {
      opacity: 1;
    }

    &.modal-exit-active {
        opacity: 0;
        transition: opacity 300ms;
    }
`;

export const ModalWrapperStyles = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
`;

export const ModalBlockStyles = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #2F2F2F;
    box-sizing: border-box;
    background-color: #fff;
    min-width: 320px;
    max-width: 500px;
    width: 100%;
    z-index: 100;
    
    & > .modal-block__header {
      display: flex;
      align-items: center;
      max-height: 80px;
      border-bottom: 1px solid #dcdcdc;
      
      & > .modal-block__header__title {
        padding: 20px 20px 20px 50px;
            flex-grow: 1;
      }
      
      & > .modal-block__header__close {
        position: relative;
        padding: 20px;
        border-left: 1px solid #dcdcdc;
        width: 24px;
        height: 24px;
        cursor: pointer;
        box-sizing: content-box;
        
        &:hover {
          background-color: #c3c3c3;
        }
      }
    }
    
    & > .modal-block__main {
      padding: 25px;
    }
`;