import {
    REFRESH_ACTION_START,
    REFRESH_ACTION_STOP, REMOVE_BLOCKED,
    SET_ACCESS_DENIED,
    SET_REQUEST_RESULT,
    START_ACTION, START_OVERLAY_LOADER,
    STOP_ACTION, STOP_OVERLAY_LOADER
} from "./constants";

const initialState = {
    startOverlayLoader: false,
    loader: {
        actions: [],
        refreshing: []
    },
    blocked: {
        actionsBlocked: []
    },
    requestResult: null
};

const uiReducer = (state = initialState, { type, payload }) => {
    const { loader, blocked } = state;
    const { actions, refreshing } = loader;
    const { actionsBlocked } = blocked;
    switch (type) {
        case START_ACTION:
            return {
                ...state,
                loader: {
                    ...loader,
                    actions: [...actions, payload.action]
                }
            };
        case STOP_ACTION:
            return {
                ...state,
                loader: {
                    ...loader,
                    actions: actions.filter(action => action.name !== payload.name)
                }
            };
        case REFRESH_ACTION_START:
            return {
                ...state,
                loader: {
                    ...loader,
                    refreshing: [...refreshing, payload.refreshAction]
                }
            };
        case REFRESH_ACTION_STOP:
            return {
                ...state,
                loader: {
                    ...loader,
                    refreshing: refreshing.filter(refresh => refresh !== payload.refreshAction)
                }
            };
        case SET_REQUEST_RESULT:
            return {
                ...state,
                requestResult: payload
            };
        case SET_ACCESS_DENIED:
            return {
                ...state,
                blocked: {
                    ...blocked,
                    actionsBlocked: [...actionsBlocked, payload.action]
                }
            };
        case REMOVE_BLOCKED:
            return {
                ...state,
                blocked: {
                    ...blocked,
                    actionsBlocked: actionsBlocked.filter(action => action.name !== payload.name)
                }
            };
        case START_OVERLAY_LOADER:
            return {
                ...state,
                startOverlayLoader: true
            };
        case STOP_OVERLAY_LOADER:
            return {
                ...state,
                startOverlayLoader: false
            };
        default:
            return state;
    }
};

export default uiReducer;