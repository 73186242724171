import React, {useEffect} from 'react';
import { ToastContainer } from 'react-toastify';
import styled from "styled-components";
import {Switch, Route, useLocation, Redirect} from 'react-router-dom';

import Header from "../components/Header";
import WhiteListPage from "../pages/WhiteListPage";
import BlackListPage from "../pages/BlackListPage";
import UsersPage from "../pages/UsersPage";
import {useDispatch, useSelector} from "react-redux";
import UserProfilePage from "../pages/UserProfilePage";
import {useCookies} from "react-cookie";
import {requestGetUsersList} from "../redux/users/action";
import UsersChildrenPage from "../pages/UsersChildrenPage";
import {renderGroupRoutes} from "../utils/helpers";
import MainPage from "../pages/MainPage";
import Nav from "../components/Nav";
import ForbiddenNumbers from "../pages/ForbiddenListPage";
import TimeSession from "../components/TimeSession/TimeSession";
import {signOut} from "../redux/auth/action";

import 'react-toastify/dist/ReactToastify.css';

const LayoutWrapperStyles = styled.div`
  display: flex;
  padding-top: 60px;
  position: relative;
  
  a {
    color: #231F20;
    text-decoration: none;
  }
`;

const LayoutNavStyles = styled.div`
    max-width: 280px;
    width: 100%;
    box-shadow: 2px 0 4px 2px rgba(0,0,0,0.08);
    height: calc(100vh - 60px);
    position: sticky;
    top: 60px;
    left: 0;
`;

const LayoutContentStyles = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding: 24px 16px;
    background-color: #ecf3f6;
`;

const MainLayout = () => {
    const {permissions} = useSelector(state => state.auth);
    const {users} = useSelector(state => state.users);
    const [{login, code}] = useCookies();
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        dispatch(requestGetUsersList(login, code));

        if(!permissions) {
            dispatch(signOut());
        }

        if(permissions && !permissions.find(item => item === 'index')) {
            dispatch(signOut());
        }
        //eslint-disable-next-line
    }, []);

    const currentUserPath = () => {
        const pathArr = location.pathname.split('/').slice(0, -1);
        return pathArr.join('/');
    }

    const checkPermission = () => {
        if(permissions) {
            const locationArr = location.pathname.split('/');
            return permissions.join(',').includes(locationArr[1]);
        }
    };

    return (permissions &&
        <div>
            <ToastContainer />
            <TimeSession />
            <Header/>
            <LayoutWrapperStyles>
                <LayoutNavStyles>
                    <Nav />
                </LayoutNavStyles>
                <LayoutContentStyles>
                    <Switch>
                        {!checkPermission() && <Redirect to='/'/> }
                        <Route exact path='/'><MainPage /></Route>
                        <Route path='/white-list'><WhiteListPage/></Route>
                        <Route path='/black-list'><BlackListPage/></Route>
                        <Route path='/users' exact><UsersPage/></Route>
                        <Route path='/forbidden-numbers'><ForbiddenNumbers /></Route>
                        {users.length > 0 && renderGroupRoutes(users).map(user => <Route key={user.id} exact
                                                                                         path={user.path}>
                            {user.children.length === 0 ?
                                <UserProfilePage user={user}/>
                                :
                                <UsersChildrenPage user={user}/>
                            }
                        </Route>)}
                        {users.length > 0 && renderGroupRoutes(users).map(user => <Route key={user.id} exact
                                                                                         path={`${currentUserPath()}/${user.merchantID}`}>
                            <UserProfilePage user={user}/>
                        </Route>)}
                    </Switch>
                </LayoutContentStyles>
            </LayoutWrapperStyles>
        </div>
    );
};

export default MainLayout;