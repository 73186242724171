import styled from "styled-components";

export const TableStyle = styled.div`
  display: grid;
  cursor: pointer;
  grid-template-columns: minmax(50px, 0.2fr) 175px 200px 1fr 150px 150px 50px;
  
  .table-header-item {
    background-color: #ffd200;
    padding: 8px 16px;
    box-sizing: border-box;
    font-weight: bold;
  }
  
  .table-item {
    border-bottom: 1px solid rgba(35,31,32,0.2);
    padding: 8px 16px;
    background-color: white;
    
    & > a:hover {
      text-decoration: underline;
    }
  }
  
  &:hover {
     & > .table-item {
          transition: all .15s ease-in-out;
          background-color: rgba(0,0,0,0.05);
     }
  }
  
  .table-item--delete {
    color: red;
    text-align: right;
    
    button {
      color: red;
      border: none;
      background-color: transparent;
      cursor: pointer;
      
      &:hover {
      
        & svg {
          stroke: red;
        }  
      }
    }
  }
  
  .table-item--bold {
    font-weight: bold;
  }
  
  .table-item--center {
    text-align: center;
  }
  
  ${props => props.mainPage && 'grid-template-columns: 1fr 0.3fr;'}
  ${props => props.whiteList && 'grid-template-columns: minmax(50px, 0.2fr) 175px 200px 1fr 50px;'}
  ${props => props.forbiddenList && 'grid-template-columns: 0.2fr 1fr 1fr 0.4fr;'}
  ${props => props.xlsxArray && 'grid-template-columns: minmax(50px, 0.2fr) 175px 1fr 150px 150px;'}
`;

export const ControlStyles = styled.div`
  display: flex;
  align-items: center;
  max-width: 540px;
  width: 100%;
  
  button {
    margin-left: 16px;
  }
`;

export const TableContainerStyles = styled.div`
  margin: 32px 0;
  
  & .list-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;