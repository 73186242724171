import React from "react";
import { useSelector } from "react-redux";
import AuthLayout from "../../layouts/AuthLayouy";
import MainLayout from "../../layouts/MainLayout";

const App = () => {
  const isAuth = useSelector((state) => state.auth.isAuth);

  if (!isAuth) {
    return <AuthLayout />;
  }

  return <MainLayout />;
};

export default App;
