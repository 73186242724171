import styled from "styled-components";

export const HeaderWrapperStyles = styled.div`
  position: fixed;
  width: 100%;
  background-color: white;
  box-shadow: 0 2px 4px 4px rgba(0,0,0,0.08);
  z-index: 999;
`;

export const HeaderStyles = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  
  .header-logo {
    margin: 0 32px;
    & > svg {
      width: 108px;
      height: 40px;
    }
  }
`;

export const UserHeaderStyles = styled.div`
    cursor: pointer;
    position: relative;
    margin: 0 30px;
`;

export const UserHeaderLogoStyles = styled.div`
    padding: 5px 8px;
    display: flex;
    align-items: center;
    
    &:hover {
      background-color: #e3e3e3;
      transition: all .2s ease-in-out;
    }
    
    & > img {
      width: 30px;
      height: 30px;
      margin-right: 8px;
    }
    
    & > svg {
      width: 11px;
      height: 16px;
    }
`;

export const UserDropDownStyles = styled.div`
  position: absolute;
  top: calc(100% + 15px);
  right: 0;
  background: white;
  width: 160px;
  text-align: right;
  box-shadow: 0 0 5px 0 rgba(0,0,0,0.15);
  
  &.dropdown-enter {
        opacity: 0;
    }
    
    &.dropdown-enter-active {
        opacity: 1;
        transition: opacity 300ms;
    }
    
    &.dropdown-exit {
        opacity: 1;
    }
    
    &.dropdown-exit-active {
        opacity: 0;
        transition: opacity 300ms;
    }
  
  & > .dropdown-item {
    padding: 6px 16px;
    
    &:hover {
      background-color: #e3e3e3;
    }
  }
  
  & > .dropdown-item--header {
    cursor: auto;
    font-weight: 500;
    padding: 6px 16px;
    border-bottom: 1px solid rgba(0,0,0,0.15);
  }
`;