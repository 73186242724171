export const REQUEST_USERS_LIST = "REQUEST_USERS_LIST";
export const SUCCESS_USERS_LIST = "SUCCESS_USERS_LIST";
export const FAILURE_USERS_LIST = "FAILURE_USERS_LIST";
export const REQUEST_USER_PROFILE = "REQUEST_USER_PROFILE";
export const SUCCESS_USER_PROFILE = "SUCCESS_USER_PROFILE";
export const FAILURE_USER_PROFILE = "FAILURE_USER_PROFILE";
export const UPDATE_PROFILE_VALUE = "UPDATE_PROFILE_VALUE";
export const INIT_PROFILE_VALUE = "INIT_PROFILE_VALUE";
export const UPDATE_PROFILE_KAF = "UPDATE_PROFILE_KAF";
export const REQUEST_MAKE_USER = "REQUEST_MAKE_USER";
export const SUCCESS_MAKE_USER = "SUCCESS_MAKE_USER";
export const FAILURE_MAKE_USER = "FAILURE_MAKE_USER";
export const DELETE_PROFILE_KAF = "DELETE_PROFILE_KAF";
export const ADD_NEW_PROFILE = "ADD_NEW_PROFILE";
export const CLEAR_PROFILE = "CLEAR_PROFILE";
export const REQUEST_DELETE_USER = "REQUEST_DELETE_USER";
export const SUCCESS_DELETE_USER = "SUCCESS_DELETE_USER";
export const FAILURE_DELETE_USER = "FAILURE_DELETE_USER";
export const UPDATE_USER_NAME = "UPDATE_USER_NAME";
export const UPDATE_BIN_CHECK = "UPDATE_BIN_CHECK";
export const UPDATE_BIN_CONFIG = "UPDATE_BIN_CONFIG";
export const UPDATE_BIN_CODE_COUNTRY = "UPDATE_BIN_CODE_COUNTRY";
export const UPDATE_ATMM_CHECK = "UPDATE_ATMM_CHECK";
export const UPDATE_ATMM_CONFIG = "UPDATE_ATMM_CONFIG";
export const UPDATE_ARAMM_CHECK = "UPDATE_ARAMM_CHECK";
export const UPDATE_ARAMM_CONFIG = "UPDATE_ARAMM_CONFIG";
export const UPDATE_TRAFFIC_SCORE_CHECK = "UPDATE_TRAFFIC_SCORE_CHECK";
export const UPDATE_TRAFFIC_SCORE_CONFIG = "UPDATE_TRAFFIC_SCORE_CONFIG";
