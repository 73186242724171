import React from 'react';
import {InputContainer} from "../styles/InputContainer";

const Input = React.forwardRef(({label, errorMessage, id, ...attrs}, ref) => {
    return (
        <InputContainer>
            {label && <label htmlFor={id}>{label}</label>}
            <input {...attrs} ref={ref} id={id} className={errorMessage && 'input-error'}/>
            {errorMessage && <span role="alert">{errorMessage}</span>}
        </InputContainer>
    );
});

export default Input;