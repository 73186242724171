import React from 'react';
import {useForm} from "react-hook-form";
import {Form} from "../styles/Form";
import Input from "../Input";
import {useDispatch, useSelector} from "react-redux";
import {ErrorMessage} from "../styles/ErrorMessag";
import {requestSignIn} from "../../redux/auth/action";

const SignIn = () => {
    const {error, loader, isAuth} = useSelector(state => state.auth);
    const {register, handleSubmit, formState: {errors}} = useForm();
    const dispatch = useDispatch();

    const onSubmit = (data) => {
        dispatch(requestSignIn(data));
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)} error={error} success={isAuth} loader={loader} aria-busy={loader}>
            <div className='header'>
                LOGIN
            </div>
            <fieldset disabled={loader}>
                <Input {...register("login", {required: true})} type="text"
                       id='login'
                       label='Логин'
                       errorMessage={errors.login && 'Введите логин'}
                       autoComplete="off"/>
                <Input {...register("password", {required: true})} type="password" id="password" label='Пароль'
                       errorMessage={errors.password && 'Введите пароль'}
                       autoComplete="off"/>
                {error && <ErrorMessage role='alert'>{error.message}</ErrorMessage>}
            </fieldset>
            <button className='auth-btn' disabled={loader}>Вход</button>
        </Form>
    );
};

export default SignIn;