import React, {useState} from "react";
import styled from 'styled-components';
import Input from "../Input";
import {ButtonStyle} from "../styles/Button";
import {requestUpdateUser} from "../../redux/users/action";
import {useDispatch} from "react-redux";
import {useCookies} from "react-cookie";
import {useHistory} from 'react-router-dom';
import {InputContainer} from "../styles/InputContainer";

const UpdateUserContainer = styled.div`
	& > ${InputContainer} {
		margin-bottom: 16px;
	}

	& > .button-list {
	    display: flex;
	    align-items: center;
	    gap: 16px;
	    
	    & > button {
	      flex: 1 0 auto;
	    }
	  }
	  
	  & > p {
	    margin-bottom: 24px;
	  }
`;

const UpdateUser = ({ user, hide}) => {
	const [{login, code}] = useCookies();
	const [name, setName] = useState(user.name);
	const [merchantId, setMerchantId] = useState(user.merchantID);
	const history = useHistory();
	const dispatch = useDispatch();

	const updateUser = () => {
		const data = {
			id: user.id,
			name: name,
			merchantID: merchantId
		};

		dispatch(requestUpdateUser({login, code, data}))
			.then(res => {
				if(res.code === 0) {
					if(merchantId !== user.merchantID) {
						let locationSplit = history.location.pathname.split('/');
						locationSplit = locationSplit.slice(0, locationSplit.length - 1);
						history.push(`${locationSplit.join('/')}/${merchantId}`)
					}else {
						hide();
					}
				}
			});
	};

	return (
		<UpdateUserContainer>
			<Input value={name} onChange={(e) => setName(e.target.value)} label='Имя клиента'/>
			<Input value={merchantId} onChange={(e) => setMerchantId(e.target.value)} label='MerchantID'/>
			<div className='button-list'>
				<ButtonStyle onClick={updateUser}>Сохранить</ButtonStyle>
				<ButtonStyle onClick={hide}>Отмена</ButtonStyle>
			</div>
		</UpdateUserContainer>
	)
};

export default UpdateUser;