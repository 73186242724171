import styled from 'styled-components';
import {Link} from "react-router-dom";

export const UsersCardsListStyles = styled.div`
    display: flex;
    align-items: center;
    margin-top: 30px;
    margin: 0 -8px;
    flex-wrap: wrap;
`;

export const UsersCardStyles = styled(Link)`
    background-color: #fff;
    cursor: pointer;
    transition: all .15s ease-in-out;
    height: 60px;
    width: calc(100% - 12px);
    margin: 0 4px 8px;
    display: flex;
    align-items: center;
    padding: 16px;
    box-sizing: border-box;
    border: 1px solid rgba(0,0,0,0.2);
    font-weight: bold;
    border-left: 3px solid #FFD200;
    justify-content: space-between;
    
    &:hover {
      background-color: #e8e8e8;
      transition: background-color .2s ease-in-out;
    }
    
    @media (min-width: 576px) {
      margin: 0 6px 10px;
      width: calc(50% - 12px);
    }
    
    @media (min-width: 768px) {
      margin: 0 8px 12px;
      width: calc(33.33333% - 16px);
    }
    
    @media (min-width: 992px) {
      width: calc(25% - 16px);
      margin: 0 8px 12px;
    }
`;
