import React, {useRef, useState} from "react";
import {
    SimpleSelectContainerStyles,
    SimpleSelectFieldStyles,
    SimpleSelectListStyles,
    SimpleSelectValueStyles
} from "../styles/Select";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import {CloseCrossStyles} from "../styles/CloseCross";

const SimpleSelect = ({ children, value, label, clear }) => {
    const [open, setOpen] = useState(false);
    const ref = useRef();

    const onHandleClick = (e) => {
        e.stopPropagation();
        setOpen(!open);
    }

    useOnClickOutside(ref, () => setOpen(false));

    return (
        <SimpleSelectContainerStyles>
            <p className='label'>{label}</p>
            <SimpleSelectFieldStyles ref={ref} onClick={onHandleClick}>
                <SimpleSelectValueStyles>{value} {value && <CloseCrossStyles onClick={clear}/>}</SimpleSelectValueStyles>
                {open && <SimpleSelectListStyles>{children}</SimpleSelectListStyles>}
            </SimpleSelectFieldStyles>
        </SimpleSelectContainerStyles>
    )
};

export default SimpleSelect;