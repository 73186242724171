import { createSelector } from 'reselect';

export const checkIfLoading = (state, ...actionsToCheck) =>
	state.loader.actions.some((action) => actionsToCheck.includes(action.name));

export const requestLoader = (type) =>
	createSelector(
		(state) => state.uiReducer,
		(loader) => {
			return {
				isLoader: checkIfLoading(loader, type),
			};
		}
	);