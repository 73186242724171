import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from "./components/App";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import store from "./store";
import { CookiesProvider } from 'react-cookie';
import '../src/media/fonts/fonts.css';

ReactDOM.render(
    <React.Fragment>
        <Provider store={store}>
            <CookiesProvider>
                <Router>
                    <App/>
                </Router>
            </CookiesProvider>
        </Provider>
    </React.Fragment>,
    document.getElementById('root')
);