import React from 'react';
import {NavStyles} from "../styles/Nav";
import PermNavLink from "../PermNavLink";

const Nav = () => {

    return (
        <NavStyles>
            <PermNavLink to='/black-list' className='nav-item' activeClassName="selected">
                Черный список
            </PermNavLink>
            <PermNavLink to='/white-list' className='nav-item' activeClassName="selected">
                Белый список
            </PermNavLink>
            <PermNavLink to='/users' className='nav-item' activeClassName="selected">
                Клиенты
            </PermNavLink>
            <PermNavLink to='/forbidden-numbers' className='nav-item' activeClassName="selected">
                Запрещенные номера
            </PermNavLink>
        </NavStyles>
    );
};

export default Nav;