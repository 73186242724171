import React from "react";
import {RangeInputStyles} from "../styles/Inputs";

const RangeInput = ({ title, value, onChange, name, min, max }) => {
    return (
        <div>
            <div>{title}</div>
            <RangeInputStyles type="range" name={name}
                              min={min.toString()} max={max.toString()} value={value} onChange={onChange} />
        </div>
    )
};

export default RangeInput;