import React, {useState} from "react";
import styled from 'styled-components';
import DOMPurify from 'dompurify';
import Button from "../Button/Button";
import TextArea from "../TextArea/TextArea";
import {ButtonList} from "../styles/Button";
import MaskInput from "../MaskInput";
import FraudCheckbox from "../FraudCheckbox";
import ISOCountry from "../../media/files/iso-3166.json";
import Select from "../Select";

const AddIpContainer = styled.div`
	& input, textarea, select, .checkmark-container {
		margin-bottom: 16px;
	}
`;

const AddIp = ({hide, onClick, tor, country}) => {
	const [formState, setFormState] = useState({
		ip: '',
		country: country,
		tor: tor,
		comment: ''
	})

	const handleChange = (e) => {
		const name = e.target.name;
		const value = e.target.value;
		setFormState({ ...formState, [name]: DOMPurify.sanitize(value) });
	};

	const handleChecked = (e) => {
		const name = e.target.name;
		const checked = e.target.checked;
		setFormState({ ...formState, [name]: checked });
	};

	const addIp = () => {
		onClick(formState);
	};

	return (
		<AddIpContainer>
			<MaskInput type='ip' placeholder='Введите IP Адрес' name='ip' value={formState.ip} guide={false} label='IP-адрес' onChange={handleChange}/>
			{country !== undefined && <Select
				name='country'
				label='country'
				value={formState.country}
				onChange={handleChange}>
				{ISOCountry.map(country => <option key={country['alpha-2']}
				                                   value={country['alpha-2']}>{country['alpha-2']} - {country.name}</option>)}
			</Select>}
			{tor !== undefined && <FraudCheckbox title='tor' name='tor' checked={formState.tor} onChange={handleChecked}/>}
			<TextArea label='Комментарий' name='comment' value={formState.comment} onChange={handleChange}/>
			<ButtonList>
				<Button onClick={addIp}>Добавить</Button>
				<Button onClick={hide}>Отмена</Button>
			</ButtonList>
		</AddIpContainer>
	)
};

export default AddIp;