import React from 'react';
import MaskedInput from 'react-text-mask'
import {InputContainer} from "../styles/InputContainer";

const MaskInput = React.forwardRef(({placeholder, type, guide, id, label, name, onChange, value}, ref) => {
    const typeMask = (type) => {
        let mask;
        let pipe = type === 'ip' ? value => {
            if (value === '.' || value.endsWith('..')) return false;

            const parts = value.split('.');

            if (
                parts.length > 4 ||
                parts.some(part => part === '00' || part < 0 || part > 255)
            ) {
                return false;
            }

            return value;
        } : null;
        switch (type) {
            case 'ip':
                mask = value => Array(value.length).fill(/[\d.]/)
                break;
            case 'phone':
                mask = ['+','7', '(', /[1-9]/, /\d/, /\d/, ')', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/];
                break;
            case 'iin':
                mask = [/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/];
                break;
            default:
                mask = [];
                break;
        }

        return {mask, pipe};
    }

    return (
        <InputContainer>
            {label && <label htmlFor={id}>{label}</label>}
            <MaskedInput
                mask={typeMask(type).mask}
                pipe={typeMask(type).pipe}
                placeholder={placeholder}
                guide={guide}
                onChange={onChange}
                ref={ref}
                name={name}
                id={id}
                value={value}
            />
        </InputContainer>
    )
});

export default MaskInput;