import styled from 'styled-components';

export const CloseCrossStyles = styled.span`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 24px;
    height: 24px;
    padding: 20px;
    opacity: 0.5;
    transform: rotate(45deg) translate(-50%, -50%);
    transform-origin: 0 0;
    cursor: pointer;
    
    &:hover {
      opacity: 1;
    }
    
    &:after, &:before {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        content: ' ';
        background-color: #231F20;
    }
    
    &:before {
      height: 16px;
      width: 2px;
    }
    
    &:after {
      height: 2px;
      width: 16px;
    }
`;