import React from 'react';

const EditPencil = ({color, size}) => {
    return (
        <svg width={`${size}px`} height={`${size}px`} viewBox="0 0 24 24" fill="none" stroke={color} strokeWidth="2"
             strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit-2">
            <polygon points="16 3 21 8 8 21 3 21 3 16 16 3" />
        </svg>
    )
};

export default EditPencil;