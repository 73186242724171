import React from 'react';
import {ButtonStyle} from "../styles/Button";

const Button = ({children, ...attrs}) => {
    return (
        <ButtonStyle {...attrs}>
            {children}
        </ButtonStyle>
    );
};

export default Button;