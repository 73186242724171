import React from "react";
import {FileInputContainerStyles} from "../styles/FileInput";
import Button from "../Button/Button";
import {CloseCrossStyles} from "../styles/CloseCross";
import {ErrorMessage} from "../styles/ErrorMessag";

const FileInput = React.forwardRef(({file, fileSelect, clear, errorMessage, setErrorMessage}, ref) => {
	const openFileSelect = () => {
		setErrorMessage(null);
		ref.current && ref.current.click();
	};

	return (
		<div>
			<FileInputContainerStyles>
				<input type="file" onChange={fileSelect} ref={ref} accept=".xlsx,.xls"/>
				<Button onClick={openFileSelect}>Импорт из XLSX</Button>
				{file ? <div className="file__info">
					<span>{file.name}</span>
					<div className="file__clear" onClick={clear}><CloseCrossStyles/></div>
				</div> : null}
			</FileInputContainerStyles>
			{errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
		</div>
	)
});

export default FileInput;