import {ButtonStyle} from "../styles/Button";
import styled from 'styled-components';
import {useDispatch} from "react-redux";
import {requestDelUser} from "../../redux/users/action";
import {useCookies} from "react-cookie";
import {useHistory} from 'react-router-dom';

const DeleteUserContainer = styled.div`
  & > .button-list {
    display: flex;
    align-items: center;
    gap: 16px;
    
    & > button {
      flex: 1 0 auto;
    }
  }
  
  & > p {
    margin-bottom: 24px;
  }
`;

const DeleteUser = ({ hide, id, currentUser }) => {
    const [{login, code}] = useCookies();
    const history = useHistory();
    const dispatch = useDispatch();
    const delUser = () => {
        dispatch(requestDelUser({ login, code, id }))
            .then(res => res.code === 0 && currentUser ? history.push('/users') : hide());
    }

    return (
        <DeleteUserContainer>
            <p>Вы уверены, что хотите удалить клиента?</p>
            <div className='button-list'>
                <ButtonStyle onClick={delUser}>Удалить</ButtonStyle>
                <ButtonStyle onClick={hide}>Отмена</ButtonStyle>
            </div>
        </DeleteUserContainer>

    )
};

export default DeleteUser;