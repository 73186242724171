import styled from 'styled-components';

export const FileInputContainerStyles = styled.div`
	display: flex;
	align-items: center;

	& > input {
		visibility: hidden;
		width: 0;
		height: 0;
		position: absolute;
	}
	
	& > .file__info {
		display: flex;
		align-items: center;
		background-color: white;
		padding: 12px 6px 12px 12px;
		max-height: 42px;
		
		& > .file__clear {
			position: relative;
			width: 30px;
			height: 12px;
		}
	}
`;