import React from "react";
import styled from 'styled-components';
import {ButtonList} from "../styles/Button";
import Button from "../Button/Button";

const DelIpContainer = styled.div`
	& > p {
		margin-bottom: 16px;
		
		& > span {
			font-weight: bold;
		}
	}
`;

const DellIp = ({ itemIp, hide, onClick }) => {

	return (
		<DelIpContainer>
			<p>Удалить IP-адрес <span>{itemIp ? itemIp.ip : ''}</span>?</p>
			<ButtonList>
				<Button onClick={onClick}>Удалить</Button>
				<Button onClick={hide}>Отмена</Button>
			</ButtonList>
		</DelIpContainer>
	)
};

export default DellIp;