import styled, {css} from 'styled-components';

export const ButtonStyle = styled.button`
  padding: 13px 16px;
  color: #231F20;
  background-color: #ffd200;
  border: none;
  font-weight: bold;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  
  &:hover {
    background-color: #e5b900;
    transition: background-color .2s ease-in-out;
  }
  
  &[disabled] {
    opacity: 0.5;
  }
  
  ${props => props.formStyle && 'width: 100%; margin-top: 16px;'}
  
  ${props => props.updateList && css`
    width: 100%;
    margin-top: 16px;
  `}
`;

export const ButtonList = styled.div`
    display: flex;
    align-items: center;
    
    & button {
        margin-right: 16px;
        flex: 1 0 auto;
        
        &:last-child {
            margin-right: 0;
        }
    }
`;