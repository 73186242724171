import React, {useState} from "react";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {UsersCardsListStyles, UsersCardStyles} from "../components/styles/Cards";
import {ErrorMessage} from "../components/styles/ErrorMessag";
import Loader from "../components/Loader/Loader";
import EditPencil from "../components/EditPencil";
import {ButtonStyle} from "../components/styles/Button";
import plusIcon from "../media/images/plus-icon.svg";
import {UsersHeaderStyles} from "../components/styles/Users";
import MakeClientForm from "../containers/MakeClientForm";
import Modal from "../components/Modal/Modal";
import useModal from "../hooks/useModal";
import TrashIcon from "../media/images/trash-icon";
import DeleteUser from "../components/DeleteUser";

const UsersChildrenPage = ({user}) => {
    const {error, loader} = useSelector(state => state.users);
    const [modal, setModal, toggleModal] = useModal();
    const [id, setId] = useState(null);
    const [delModal, setDelModal, toggleDelModal] = useModal();

    const delModalOpen = (e, id) => {
        toggleDelModal();
        setId(id)
        e.preventDefault();
    }

    return (
        <div>
            {error && <ErrorMessage>{error.message}</ErrorMessage>}
            <Modal isShowing={modal} hide={() => setModal(false)} title='Добавить клиента'>
                <MakeClientForm hide={() => setModal(false)}/>
            </Modal>
            <Modal isShowing={delModal} hide={() => setDelModal(false)} title='Удалить клиента'>
                <DeleteUser hide={() => setDelModal(false)} id={id}/>
            </Modal>
            <UsersHeaderStyles><h2>{user.name}<Link title='Редактировать' to={`/users/${user.merchantID}`}><EditPencil
                size={24}
                color={'#000000'}/></Link></h2><ButtonStyle onClick={toggleModal}>Добавить клиента
                <img src={plusIcon}
                     alt='plus icon'/></ButtonStyle></UsersHeaderStyles>
            {loader && <Loader/>}
            {user.children.length > 0 ? <UsersCardsListStyles>
                    {user.children.map(user => <UsersCardStyles to={user.path}
                                                                key={user.id}>{user.name}
                        <div className='delete' title='Удалить' onClick={(e) => delModalOpen(e, user.id)}><TrashIcon size={18} color='#000'/>
                        </div>
                    </UsersCardStyles>)}
                </UsersCardsListStyles> :
                !loader && <h3>Список пользователей пуст</h3>
            }
        </div>
    )
};

export default UsersChildrenPage;