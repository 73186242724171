import React from "react";
import './loader.css';

const Loader = ({type, text}) => {

    let loader;

    switch (type) {
        case "content":
            loader = <div className='loader-container'>
                <div className="loadingio-spinner-spinner-nm4xms9n0oq">
                    <div className="ldio-x3aj2sv26gh">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>;
            break;
        case "overlay":
            loader = <div className='loader-overlay'><div className='loader-container'>
                <div className="loadingio-spinner-spinner-nm4xms9n0oq">
                    <div className="ldio-x3aj2sv26gh">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div></div>;
            break;
        default:
            loader = <div className='loader-container'>
                <div className="loadingio-spinner-spinner-nm4xms9n0oq">
                    <div className="ldio-x3aj2sv26gh">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
                {text && <div className='loader-text'>
                    <span>{text}</span>
                </div>}
            </div>
    }

    return (
        loader
    )
};

export default Loader;