import {
    FAILURE_WHITE_LIST, FAILURE_WHITE_LIST_ITEM, GET_WHITE_LIST_TOTAL,
    REQUEST_WHITE_LIST,
    REQUEST_WHITE_LIST_ITEM,
    SUCCESS_WHITE_LIST,
    SUCCESS_WHITE_LIST_ITEM
} from "./constants";

const initialState = {
    whiteList: [],
    error: false,
    loader: false,
    total: null,
    whiteListItem: null
};

const whiteList = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_WHITE_LIST:
        case REQUEST_WHITE_LIST_ITEM:
            return {
                ...state,
                loader: true,
                error: null
            }
        case SUCCESS_WHITE_LIST:
            return {
                ...state,
                whiteList: action.payload.ips,
                loader: false
            };
        case SUCCESS_WHITE_LIST_ITEM:
            return {
                ...state,
                whiteListItem: action.payload,
                loader: false
            }
        case GET_WHITE_LIST_TOTAL:
            return {
                ...state,
                total: action.payload
            }
        case FAILURE_WHITE_LIST:
        case FAILURE_WHITE_LIST_ITEM:
            return {
                ...state,
                error: action.payload,
                loader: false
            }
        default:
            return state;
    }
};

export default whiteList;