import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import {
  updateArammConfigValue,
  updateArammValue,
} from "../../redux/users/action";
import FraudCheckbox from "../FraudCheckbox";
import RangeInput from "../RangeInput";

const ArammConfigStyles = styled.div`
  padding: 10px 16px 16px 16px;
  & label {
    margin-bottom: 12px;
  }
`;

const ArammConfig = ({ userProfile }) => {
  const range = { max: 100, min: 0 };
  const dispatch = useDispatch();

  const updateArammCheckbox = (e) => {
    const value = e.target.checked;
    dispatch(updateArammValue(value));
  };

  const updateArammConfigRange = (e) => {
    const name = e.target.name;
    const value = Number(e.target.value);
    dispatch(updateArammConfigValue(value, name));
  };

  return (
    <div>
      <FraudCheckbox
        checked={userProfile.aramm.status || false}
        title="aramm"
        name="aramm"
        value={userProfile.aramm.status || false}
        onChange={(e) => updateArammCheckbox(e)}
      />
      {userProfile.aramm.status && (
        <ArammConfigStyles>
          <RangeInput
            value={userProfile.aramm.profiles.registration}
            onChange={(e) => updateArammConfigRange(e)}
            name="registration"
            title={`registration - ${userProfile.aramm.profiles.registration}`}
            max={range.max}
            min={range.min}
          />
          <RangeInput
            value={userProfile.aramm.profiles.authorization}
            onChange={(e) => updateArammConfigRange(e)}
            name="authorization"
            title={`authorization - ${userProfile.aramm.profiles.authorization}`}
            max={range.max}
            min={range.min}
          />
        </ArammConfigStyles>
      )}
    </div>
  );
};

export default ArammConfig;
