import {
  CLEAR_PROFILE,
  DELETE_PROFILE_KAF,
  FAILURE_DELETE_USER,
  FAILURE_MAKE_USER,
  FAILURE_USER_PROFILE,
  FAILURE_USERS_LIST,
  INIT_PROFILE_VALUE,
  REQUEST_DELETE_USER,
  REQUEST_MAKE_USER,
  REQUEST_USER_PROFILE,
  REQUEST_USERS_LIST,
  SUCCESS_DELETE_USER,
  SUCCESS_MAKE_USER,
  SUCCESS_USER_PROFILE,
  SUCCESS_USERS_LIST,
  UPDATE_PROFILE_KAF,
  UPDATE_PROFILE_VALUE,
  UPDATE_USER_NAME,
  UPDATE_BIN_CHECK,
  UPDATE_BIN_CONFIG,
  UPDATE_BIN_CODE_COUNTRY,
  UPDATE_ATMM_CHECK,
  UPDATE_ATMM_CONFIG,
  UPDATE_ARAMM_CHECK,
  UPDATE_ARAMM_CONFIG,
  UPDATE_TRAFFIC_SCORE_CHECK,
  UPDATE_TRAFFIC_SCORE_CONFIG,
} from "./constants";
import update from "react-addons-update";

const initialState = {
  users: [],
  error: null,
  loader: false,
  userProfile: null,
  defaultUserProfile: null,
};

const users = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_USERS_LIST:
    case REQUEST_USER_PROFILE:
    case REQUEST_MAKE_USER:
    case REQUEST_DELETE_USER:
      return {
        ...state,
        loader: true,
        error: null,
      };
    case SUCCESS_USERS_LIST:
      return {
        ...state,
        loader: false,
        users: action.payload,
      };
    case SUCCESS_MAKE_USER:
    case SUCCESS_DELETE_USER:
      return {
        ...state,
        loader: false,
      };
    case FAILURE_DELETE_USER:
    case FAILURE_USERS_LIST:
    case FAILURE_MAKE_USER:
    case FAILURE_USER_PROFILE:
      return {
        ...state,
        loader: false,
        error: action.payload,
      };
    case SUCCESS_USER_PROFILE:
      return {
        ...state,
        loader: false,
        error: null,
        userProfile: action.payload,
        defaultUserProfile: action.payload,
      };
    case UPDATE_USER_NAME:
      return update(state, {
        users: {
          [action.idxUser]: {
            [action.name]: { $set: action.value },
          },
        },
      });
    case UPDATE_PROFILE_VALUE:
      return update(state, {
        userProfile: {
          profiles: {
            [action.idxProf]: {
              [action.name]: { $set: action.value },
            },
          },
        },
      });
    case DELETE_PROFILE_KAF:
      return update(state, {
        userProfile: {
          profiles: {
            $splice: [[action.idxProf, 1]],
          },
        },
      });
    case UPDATE_PROFILE_KAF:
      return update(state, {
        userProfile: {
          [action.name]: { $set: action.value },
        },
      });
    case UPDATE_BIN_CHECK:
      return update(state, {
        userProfile: {
          bin_check: {
            status: { $set: action.value },
          },
        },
      });
    case UPDATE_BIN_CONFIG:
      return update(state, {
        userProfile: {
          bin_check: {
            profiles: { [action.name]: { $set: action.value } },
          },
        },
      });
    case UPDATE_BIN_CODE_COUNTRY:
      return update(state, {
        userProfile: {
          bin_check: {
            country_code: {
              $apply: function (countryCode) {
                if (!countryCode) {
                  return { status: action.value };
                } else {
                  return update(countryCode, {
                    status: { $set: action.value },
                  });
                }
              },
            },
          },
        },
      });
    case UPDATE_ATMM_CHECK:
      return update(state, {
        userProfile: {
          atmm: {
            status: { $set: action.value },
          },
        },
      });
    case UPDATE_ATMM_CONFIG:
      return update(state, {
        userProfile: {
          atmm: {
            topics: { [action.name]: { $set: action.value } },
          },
        },
      });
    case UPDATE_TRAFFIC_SCORE_CHECK:
      return update(state, {
        userProfile: {
          trafficScore: {
            $set: {
              status: action.value,
            },
          },
        },
      });
    case UPDATE_TRAFFIC_SCORE_CONFIG:
      return update(state, {
        userProfile: {
          trafficScore: {
            count: { $set: action.value },
          },
        },
      });
    case UPDATE_ARAMM_CHECK:
      return update(state, {
        userProfile: {
          aramm: {
            status: { $set: action.value },
          },
        },
      });
    case UPDATE_ARAMM_CONFIG:
      return update(state, {
        userProfile: {
          aramm: {
            profiles: { [action.name]: { $set: action.value } },
          },
        },
      });
    case INIT_PROFILE_VALUE:
      return {
        ...state,
        userProfile: state.defaultUserProfile,
      };
    case CLEAR_PROFILE:
      return {
        ...state,
        loader: false,
        error: null,
        userProfile: null,
        defaultUserProfile: null,
      };
    default:
      return state;
  }
};

export default users;
