import React, {useEffect, useState} from 'react';
import MaskInput from "../components/MaskInput";
import Button from "../components/Button/Button";
import {ErrorMessage} from "../components/styles/ErrorMessag";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import {
    clearWhiteListItem,
    requestAddWhiteListIp,
    requestDelWhiteListIp,
    requestWhiteList, requestWhiteListItem
} from "../redux/whiteList/action";
import {ControlStyles, TableContainerStyles, TableStyle} from "../components/styles/Table";
import Loader from "../components/Loader/Loader";
import { useCookies } from "react-cookie";
import {Controller, useForm} from "react-hook-form";
import {ListControlContainerStyles, ListHeaderStyles} from "./BlackListPage";
import TrashIcon from "../media/images/trash-icon";
import Modal from "../components/Modal/Modal";
import AddIp from "../components/AddIp";
import DellIp from "../components/DelIp";
import ItemIp from "../components/ItemIp";
import useModal from "../hooks/useModal";

const WhiteListPage = () => {
    const {whiteList, error, loader, whiteListItem, total} = useSelector(state => state.whiteList);
    const {control, handleSubmit, reset, formState: {isValid}} = useForm({ mode: "onChange" });
    const [modal, setModal, toggleModal] = useModal();
    const [delModal, setDelModal] = useModal();
    const [ipModal, setIpModal] = useModal();
    const [{login, code}] = useCookies();
    const [itemIp, setItemIp] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(requestWhiteList(login, code, 100, 0));
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!delModal) {
            setTimeout(() => {
                setItemIp(null);
            }, 300)
        }
    }, [delModal]);

    useEffect(() => {
        if (!ipModal) {
            setTimeout(() => {
                setItemIp(null);
            }, 300);
        }
    }, [ipModal]);

    const clearItem = () => {
        dispatch(clearWhiteListItem());
    };

    const addIp = (data) => {
        dispatch(requestAddWhiteListIp(login, code, data))
            .then(res => {
                if(res.code === 0) {
                    dispatch(requestWhiteList(login, code, whiteList.length < 100 ? 100 : whiteList.length, 0));
                    setModal(false);
                }
            });
    };

    const deleteIp = (ip, single) => {
        if(single) {
            dispatch(requestDelWhiteListIp(login, code, ip))
                .then(res => {
                    if(res.code === 0) {
                            clearItem();
                            dispatch(requestWhiteList(login, code, whiteList.length < 100 ? 100 : whiteList.length, 0));
                            setDelModal(false);
                        }
                    }
                );
        }else {
            dispatch(requestDelWhiteListIp(login, code, ip))
                .then(res => {
                    if(res.code === 0) {
                        dispatch(requestWhiteList(login, code, whiteList.length < 100 ? 100 : whiteList.length, 0 ));
                        setDelModal(false);
                    }
                });
        }
    };

    const updateList = () => {
        dispatch(requestWhiteList(login, code, 100, whiteList.length === 0 ? 0 : whiteList[whiteList.length - 1].id, whiteList));
    }

    const searchIP = (data) => {
        dispatch(requestWhiteListItem(login, code, data.ip));
    }

    const clearListItem = () => {
        dispatch(clearWhiteListItem());
        reset({ip: ''},{keepDefaultValues: false});
    }

    const openItem = (item) => {
        setItemIp(item);
        setIpModal(true);
    };

    const openDelIp = (e, item) => {
        setItemIp(item);
        setDelModal(true);
        e.stopPropagation();
    };

    return (
        <div>
            {error && <ErrorMessage>{error.message}</ErrorMessage>}
            <Modal isShowing={modal} hide={() => setModal(false)} title='Добавить IP-адрес'>
                <AddIp onClick={addIp} hide={() => setModal(false)} />
            </Modal>
            <Modal isShowing={delModal} hide={() => setDelModal(false)} title='Удалить IP-адрес'>
                <DellIp itemIp={itemIp} hide={() => setDelModal(false)} onClick={() => deleteIp(itemIp.ip, !!whiteListItem)}/>
            </Modal>
            <Modal isShowing={ipModal} hide={() => setIpModal(false)} title={`IP ${itemIp && itemIp.ip}`}>
                <ItemIp itemIp={itemIp}/>
            </Modal>
            <ListControlContainerStyles>
                <Button onClick={toggleModal}>Добавить ip-адрес</Button>
                <form onSubmit={handleSubmit(searchIP)}>
                    <ControlStyles>
                        <Controller
                            name="ip"
                            control={control}
                            rules={{required: true, pattern: /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/}}
                            defaultValue=""
                            render={({field}) =>
                                <MaskInput type='ip' placeholder='Введите IP Адрес' guide={false} {...field}/>}
                        />
                        <Button disabled={!isValid}>Поиск</Button>
                    </ControlStyles>
                </form>
            </ListControlContainerStyles>
            <TableContainerStyles>
                {loader && <Loader />}
                {whiteListItem ?
                    <>
                        <ListHeaderStyles><h3>Список разрешенных IP адресов</h3> <Button onClick={clearListItem}>Сбросить</Button></ListHeaderStyles>
                        <TableStyle whiteList>
                            <div className='table-header-item'>№</div>
                            <div className='table-header-item'>IP</div>
                            <div className='table-header-item'>Дата</div>
                            <div className='table-header-item'>Комментарий</div>
                            <div className='table-header-item'/>
                        </TableStyle>
                        {whiteListItem.map((item, index) => (
                            <TableStyle whiteList key={index} onClick={() => openItem(item)}>
                                <div className='table-item'>{item.id}</div>
                                <div className='table-item table-item--bold'>{item.ip}</div>
                                <div
                                    className='table-item'>{moment(item.date, 'YYYYMMDDhhmmss').format('DD.MM.YYYY hh:mm')}</div>
                                <div className='table-item'>{item.comment}</div>
                                <div className='table-item table-item--delete' title='Удалить'>
                                    <button onClick={(e) => openDelIp(e, item)}><TrashIcon size={16} color={'#000'}/></button>
                                </div>
                            </TableStyle>
                        ))}
                    </>
                    : whiteList.length > 0 ?
                    <>
                        <ListHeaderStyles><h3>Список разрешенных IP адресов</h3> {total && <span>Количество: {total}</span>}</ListHeaderStyles>
                        <TableStyle whiteList>
                            <div className='table-header-item'>№</div>
                            <div className='table-header-item'>IP</div>
                            <div className='table-header-item'>Дата</div>
                            <div className='table-header-item'>Комментарий</div>
                            <div className='table-header-item'/>
                        </TableStyle>
                        {whiteList.map((item, index) => (
                            <TableStyle whiteList key={index} onClick={() => openItem(item)}>
                                <div className='table-item'>{index + 1}</div>
                                <div className='table-item table-item--bold'>{item.ip}</div>
                                <div
                                    className='table-item'>{moment(item.date, 'YYYYMMDDhhmmss').format('DD.MM.YYYY hh:mm')}</div>
                                <div className='table-item'>{item.comment}</div>
                                <div className='table-item table-item--delete' title='Удалить'>
                                    <button onClick={(e) => openDelIp(e, item)}><TrashIcon size={16} color={'#000'}/></button>
                                </div>
                            </TableStyle>
                        ))}
                        {total > 100 && <Button updateList onClick={updateList}>Загрузить ещё</Button>}
                    </>
                    :
                    !loader && <h3>Список IP адресов пуст</h3>
                }
            </TableContainerStyles>
        </div>
    );
};

export default WhiteListPage;