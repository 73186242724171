import React from "react";
import styled from 'styled-components';
import moment from "moment";
import ISOCountry from '../../media/files/iso-3166.json';

const ItemIpRow = styled.div`
	margin-bottom: 8px;
	
	& > h4 {
		margin-bottom: 4px;
	}
	
	& > .disable {
		background-color: #dc8181;
		padding: 4px 12px;
	}
	
	& > .active {
		background-color: #86dc86;
		padding: 4px 12px;
	}
`;

const ItemIp = ({ itemIp }) => {
	const countryCode = () => {
		const country = ISOCountry.find(item => item['alpha-2'] === itemIp.country);
		return `${country['alpha-2']} - ${country.name}`;
	}

	return (itemIp &&
			<div>
				<ItemIpRow>
					<h4>IP адрес</h4>
					<div>{itemIp.ip}</div>
				</ItemIpRow>
				<ItemIpRow>
					<h4>Дата</h4>
					<div>{moment(itemIp.date, 'YYYYMMDDhhmmss').format('DD.MM.YYYY hh:mm')}</div>
				</ItemIpRow>
				{itemIp.country &&
				<ItemIpRow>
					<h4>Код страны</h4>
					<div>{countryCode()}</div>
				</ItemIpRow>
				}
				{itemIp.top &&
				<ItemIpRow>
					<div className={itemIp.tor ? 'active' : 'disable'}>TOR</div>
				</ItemIpRow>
				}
				<ItemIpRow>
					<h4>Комментарий</h4>
					<div>{itemIp.comment}</div>
				</ItemIpRow>
			</div>
	)
};

export default ItemIp;