import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import {
  updateTrafficScoreConfigValue,
  updateTrafficScoreValue,
} from "../../redux/users/action";
import FraudCheckbox from "../FraudCheckbox";
import RangeInput from "../RangeInput";

const TrafficScoreConfigStyles = styled.div`
  padding: 10px 16px 16px 16px;
  & label {
    margin-bottom: 12px;
  }
`;

const TrafficScoreConfig = ({ userProfile }) => {
  const range = { max: 100, min: 0 };
  const dispatch = useDispatch();

  const updateTrafficScoreCheckbox = (e) => {
    const value = e.target.checked;
    dispatch(updateTrafficScoreValue(value));
  };

  const updateTrafficScoreConfigRange = (e) => {
    const value = Number(e.target.value);
    dispatch(updateTrafficScoreConfigValue(value));
  };

  return (
    <div>
      <FraudCheckbox
        checked={
          userProfile.trafficScore ? userProfile.trafficScore.status : false
        }
        title="trafficScore"
        name="status"
        value={
          userProfile.trafficScore ? userProfile.trafficScore.status : false
        }
        onChange={(e) => updateTrafficScoreCheckbox(e)}
      />
      {userProfile.trafficScore && userProfile.trafficScore.status && (
        <TrafficScoreConfigStyles>
          <RangeInput
            value={userProfile.trafficScore.count || 0}
            onChange={(e) => updateTrafficScoreConfigRange(e)}
            name="count"
            title={`count - ${userProfile.trafficScore.count || 0}`}
            max={range.max}
            min={range.min}
          />
        </TrafficScoreConfigStyles>
      )}
    </div>
  );
};

export default TrafficScoreConfig;
