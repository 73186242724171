import {combineReducers} from "redux";
import auth from "./redux/auth/reducer";
import whiteList from "./redux/whiteList/reducer";
import blackList from "./redux/blackList/reducer";
import users from "./redux/users/reducer";
import forbiddenList from "./redux/forbiddenNumbers/reducer";
import uiReducer from "./redux/ui/reducer";

export const rootReducer = combineReducers({
    auth,
    whiteList,
    blackList,
    users,
    forbiddenList,
    uiReducer,
});